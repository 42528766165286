import React from 'react';

const SucessPaypal = () => {
    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-100">
            <div className="bg-white shadow-lg rounded-lg mb-48 p-8 max-w-lg mx-auto text-center">
                <svg className="w-16 h-16 mx-auto mb-6 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 11l3 3L22 4" />
                </svg>
                <h1 className="text-2xl font-semibold mb-4 text-gray-800">Abonnement Erfolgreich!</h1>
                <p className="text-lg text-gray-600 mb-6">
                    Ihr Abonnement wurde erfolgreich bearbeitet. Vielen Dank für Ihr Vertrauen zu uns!
                </p>
                <a href="/add"
                   className="inline-block bg-sky-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 transition duration-300">
                    Freelance Job hochladen
                </a>
            </div>
        </div>
    );
};

export default SucessPaypal;
