export const cards = [
    {
        id: 1,
        cat: "chatbots",
        desc: "AI Chatbots",
        title: "Chatbots durchsuchen",
        img: "../images/chatgpt.jpg",
    },
    {
        id: 2,
        cat: "webdesign",
        title: "Website Design",
        desc: "Finde einen UI/UX Designer",
        img: "../images/bot.jpg",
    },
    {
        id: 3,
        cat: "webdev",
        title: "Website Programmierung",
        desc: "Finde Programmierer",
        img: "../images/aichatbots.jpg",
    },
    {
        id: 4,
        cat: "shopifycustom",
        title: "Shopify Erstellung",
        desc: "Finde Shopify Profis",
        img: "../images/shopify.jpg",
    },
    {
        id: 5,
        cat: "youtube",
        title: "YouTube Videos",
        desc: "Lasse deine Videos erstellen",
        img: "../images/videoedit.png"
    },
    {
        id: 6,
        cat: "thumbnail",
        title: "Thumbnails",
        desc: "Lasse deine Thumbnails machen",
        img: "../images/thumbnail.jpg"
    },
    {
        id: 7,
        cat: "logodesign",
        title: "Logo design",
        desc: "Finde Logo design experten",
        img: "../images/logodesign.png"
    },
    {
        id: 8,
        cat: "shorts",
        title: "Short videos",
        desc: "Gehe Viral mit Shorts",
        img: "../images/shorts.jpg"
    },
    {
        id: 9,
        cat: "seo",
        title: "SEO",
        desc: "Sei #1 auf Google",
        img: "../images/seo.jpg"
    },
    {
        id: 10,
        cat: "smm",
        title: "Social Media Marketing",
        desc: "Richtiges Social Media Marketing",
        img: "../images/smm.jpg"
    },
    {
        id: 11,
        cat: "emm",
        title: "E-Mail Marketing",
        desc: "Das richtige E-Mail Marketing",
        img: "../images/em.jpg"
    },
    {
        id: 12,
        cat: "branding",
        title: "Brand",
        desc: "Heb deine Brand von anderen ab",
        img: "../images/branding.jpg"
    },
    {
        id: 13,
        cat: "translation",
        title: "Übersetzungen",
        desc: "Lasse Texte übersetzen",
        img: "../images/translation.jpg"
    },
    {
        id: 14,
        cat: "blogs",
        title: "Blog",
        desc: "Starte deinen Blog",
        img: "../images/blogging.jpg"
    },
    {
        id: 15,
        cat: "tiktok",
        title: "TikTok",
        desc: "Gehe auf TikTok viral",
        img: "../images/viral.png"
    },
    {
        id: 16,
        cat: 'ml',
        title: 'Machine Learning',
        desc: 'Finde ML Experten',
        img: '../images/ml.jpg'
    },
    {
        id: 17,
        cat: 'dl',
        title: 'Deep Learning',
        desc: 'Lass AI weiter trainieren',
        img: '../images/ml.jpg'
    },
    {
        id: 18,
        cat: 'neuronetze',
        title: 'Neuronale Netze',
        desc: 'Lasse Neuronale Netze entwickeln',
        img: '../images/neuralnetwork.jpg'
    },
    {
        id: 19,
        cat: 'wordpress',
        title: 'Wordpress',
        desc: 'Wordpress Experten',
        img: '../images/wordpress.jpg'
    },
    {
        id: 20,
        cat: 'landingpage',
        title: 'Landingpage Entwickler',
        desc: 'Landingpage für Websites',
        img: '../images/landingpage.jpg'
    },
    {
        id: 21,
        cat: 'frontend',
        title: 'Frontend Entwickler',
        desc: 'Finde Frontend Experten',
        img: '../images/frontend.jpg'
    },
    {
        id: 22,
        cat: 'backend',
        title: 'Backend Entwickler',
        desc: 'Finde Backend Experten',
        img: '../images/backend.jpg'
    },
    {
        id: 23,
        cat: 'fullstack',
        title: 'Fullstack Entwickler',
        desc: 'Finde Fullstack Programmierer',
        img: '../images/fullstack.jpg'
    },
    {
        id: 24,
        cat: 'bugfixing',
        title: 'Bug Fixen',
        desc: 'Lasse deine Bugs fixen',
        img: '../images/bugfixing.jpg'
    },
    {
        id: 25,
        cat: 'speedoptimization',
        title: 'Website Speed Optimierung',
        desc: 'Speed optimieren lassen',
        img: '../images/websitespeed.jpg'
    },
    {
        id: 26,
        cat: 'iosapp',
        title: 'iOS App Programmierung',
        desc: 'iOS App Entwicklung',
        img: '../images/iosdev.jpg'
    },
    {
        id: 27,
        cat: 'androidapp',
        title: 'Android App Programmierung',
        desc: 'Android App Entwicklung',
        img: '../images/androiddev.jpg'
    },
    {
        id: 28,
        cat: 'crossplattform',
        title: 'Crossplattform App Programmierung',
        desc: 'Crossplattform App Entwicklung',
        img: '../images/crossappdev.png'
    },
    {
        id: 29,
        cat: 'pwa',
        title: 'PWA Programmierung',
        desc: 'PWA Entwicklung',
        img: '../images/pwa.jpg'
    },
    {
        id: 30,
        cat: 'mysql',
        title: 'MySQL Datenbank',
        desc: 'MySQL Datenbank Entwicklung',
        img: '../images/mysql.jpg'
    },
    {
        id: 31,
        cat: 'mongodb',
        title: 'MongoDB Datenbank',
        desc: 'MongoDB Datenbank Entwicklung',
        img: '../images/mongodb.jpg'
    },
    {
        id: 32,
        cat: 'qareviews',
        title: 'Q&A Reviews',
        desc: 'Holen Sie sich Feedback',
        img: '../images/qareview.jpg'
    },
    {
        id: 33,
        cat: 'usertests',
        title: 'User Tests',
        desc: 'Führen Sie User Tests durch',
        img: '../images/usertest.jpg'
    }
];