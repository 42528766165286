import React from 'react';
import {FaCheck} from "react-icons/fa";

const LearnFromTheBestCta = () => {
    return (
        <div className="py-8 sm:py-36 -mt-16 rounded-3xl">
            <div className="mx-auto px-4 lg:px-12">
                <div className="mx-auto max-w-5xl lg:max-w-full lg:flex lg:items-center lg:justify-between">
                    <div className="lg:w-1/2 lg:text-left">
                        <h1 className="text-4xl font-bold text-center lg:text-left leading-10 text-sky-500">
                            Veröffentlichen Sie Ihr erstes Angebot
                        </h1>
                        <p className="text-md mt-12 font-semibold tracking-tight text-gray-900 sm:text-xl flex items-center">
                            <FaCheck className="mr-2 text-3xl" />
                            Präsentieren Sie Ihre Fähigkeiten einem globalen Publikum. Erstellen Sie Ihr erstes
                            Freelancer-Angebot in nur wenigen Minuten.
                        </p>
                        <p className="text-md mt-2 font-semibold tracking-tight text-gray-900 sm:text-xl flex items-center">
                            <FaCheck className="mr-2 text-5xl" />
                            Sie können selbst entscheiden, ob Sie für Ihr gesamtes Projekt oder per Stunde bezahlt
                            werden möchten. Verpassen Sie nicht Ihre Chance, Ihr Geschäft zu erweitern und neue Kunden
                            zu gewinnen.
                        </p>
                        <p className="text-md mt-2 font-semibold tracking-tight text-gray-900 sm:text-xl flex items-center">
                            <FaCheck className="mr-2 text-2xl" />
                            Registrieren Sie sich jetzt und machen Sie den ersten Schritt, um neue Kunden zu gewinnen.
                        </p>
                    </div>

                    <div className="lg:w-1/2 lg:ml-8 mt-8 lg:mt-0">
                        <img
                            src="/images/freela.jpg"
                            alt="freelancer"
                            loading="lazy"
                            className="w-full h-auto object-cover rounded-full shadow-lg"
                        />
                    </div>
                </div>

                <div className="mx-auto max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
                    <div className="mt-8 sm:mt-10 flex items-center justify-center">
                        <a
                            href="/register"
                            className="rounded-full bg-indigo-500 px-6 py-3 sm:px-8 sm:py-4 text-base sm:text-lg font-semibold text-gray-950 shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
                        >
                            Jetzt Registrieren
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LearnFromTheBestCta;