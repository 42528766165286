import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Link from "@mui/material/Link";
import newRequest from "../../utils/newRequest";

function Login() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const res = await newRequest.post("/auth/login", { username, password });
            if (res.data) {
                localStorage.setItem("currentUser", JSON.stringify(res.data));
                    navigate("/myservices");
            } else {
                setError('Fehler: Ungültige Anmeldeinformationen');
            }
        } catch (err) {
            setError('Fehler: Falscher Benutzername oder Passwort');
        }
    };

    return (
        <div className="max-w-md mx-auto bg-white shadow-lg rounded-lg p-8 mt-10">
            <div className="flex justify-center mb-6">
                <Link href="/" underline="none">
                    <h1 className="text-2xl font-semibold text-blue-600">planetbots</h1>
                </Link>
            </div>
            <h2 className="text-2xl font-bold mb-6 text-center">Anmelden</h2>
            {error && <p className="text-red-500 mb-4 text-center animate-bounce">{error}</p>}
            <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                    <input
                        type="text"
                        id="username"
                        placeholder="Benutzername"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        className="w-full p-3 border border-gray-300 rounded-full focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors"
                    />
                </div>
                <div>
                    <input
                        type="password"
                        id="password"
                        placeholder="Passwort"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="w-full p-3 border border-gray-300 rounded-full focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors"
                    />
                </div>
                <div className="flex justify-between items-center">
                    <p>
                        <Link href="/forgot-password" className="text-sky-500 hover:underline">Passwort
                            vergessen?</Link>
                    </p>
                </div>
                <button
                    type="submit"
                    className='w-full py-3 bg-sky-500 text-white rounded-full flex items-center justify-center hover:bg-sky-600 focus:outline-none focus:ring-4 focus:ring-blue-300 transition-colors'
                >
                        Anmelden
                </button>
            </form>
            <p className="text-center mt-4 text-lg">
                Noch keinen Account?{" "}
                <Link href="/register" className="text-sky-500 hover:underline">
                    Hier Registrieren
                </Link>
            </p>
        </div>
    );
}

export default Login;
