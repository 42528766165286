import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div className="container mx-auto py-8">
            <h2 className="text-3xl font-bold mb-4 text-center">Datenschutzrichtlinie</h2>
            <div className="flex flex-wrap justify-center">
                Datenschutz ist uns wichtig. Diese Datenschutzrichtlinie erklärt, wie planetbots persönliche Daten
                sammelt, verwendet und schützt.<br/>

                Sammlung und Verwendung von Informationen:<br/>

                Wir sammeln persönliche Daten, um Ihr Erlebnis zu personalisieren, Dienstleistungen bereitzustellen und
                Zahlungen zu verarbeiten. Die gesammelten Informationen können Ihren Namen, Kontaktdaten,
                Zahlungsinformationen und andere relevante Daten umfassen. Ihre Daten werden nur für den angegebenen
                Zweck verwendet und nicht an Dritte verkauft oder weitergegeben, es sei denn, dies ist gesetzlich
                erforderlich.<br/>

                Datensicherheit:<br/>

                Wir treffen angemessene Sicherheitsmaßnahmen, um Ihre Daten vor unbefugtem Zugriff, Gebrauch oder
                Offenlegung zu schützen. Trotz unserer Bemühungen können wir keine absolute Sicherheit garantieren, und
                Sie sollten auch geeignete Maßnahmen zum Schutz Ihrer Daten treffen.<br/>

                Cookies und Tracking-Technologien:<br/>

                Wir verwenden Cookies und ähnliche Technologien, um Informationen zu sammeln und Ihr Erlebnis auf
                unserer Website zu verbessern. Sie können die Verwendung von Cookies in den Browsereinstellungen
                steuern, aber das Deaktivieren von Cookies kann die Funktionalität unserer Website beeinträchtigen.<br/>

                Änderungen der Datenschutzrichtlinie:<br/>

                Wir können diese Datenschutzrichtlinie von Zeit zu Zeit aktualisieren. Bitte überprüfen Sie regelmäßig,
                ob es Updates gibt.
            </div>
        </div>
    );
};

export default PrivacyPolicy;