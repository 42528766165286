import React from 'react';

const About = () => {

    const stats = [
        { id: 1, name: 'Freelancer Kategorien', value: '25+' },
        { id: 2, name: 'Verfügbarkeitsgarantie', value: '99,9%' },
        { id: 3, name: 'Kundensupport', value: '24/7' },
    ];

    return (
        <div className="flex flex-col justify-center items-center bg-white">
            <h1 className="text-4xl font-bold mb-4">Unsere Mission</h1>
            <p className="text-lg mb-6 max-w-screen-lg">
                Unsere Mission ist es, Ihnen mehr Zeit für Familie und Freunde zu ermöglichen. Wir wollen, dass Sie von
                überall auf der Welt arbeiten können. Egal ob Sie in den Malediven Kokosnüsse trinken oder am Kamin in
                einer Skihütte sitzen – Sie allein entscheiden, wann Sie arbeiten und wann nicht. Knüpfen Sie neue
                Kontakte und bauen Sie Ihr Netzwerk für zukünftige Projekte aus. Und das Beste daran? Keine Gebühren für
                unseren Service, keine versteckten Kosten, und keine Prozente vom Verkaufspreis.
            </p>
            <p className="text-lg mb-6 max-w-screen-lg">
                Wir bieten die besten Freelancer. Wir möchten, dass Kunden die besten Freelancer finden. Wir wissen, wie
                enttäuschend es sein kann, für eine Dienstleistung zu bezahlen und nicht zufrieden zu sein. Daher arbeiten
                wir nur mit den Besten. Wir prüfen die Erfahrung des Freelancers, wie lange er schon im Geschäft ist und
                wie viele Projekte er erfolgreich abgeschlossen hat.
            </p>
            <p className="text-lg mb-6 max-w-screen-lg">
                Planetbots ist eine Online-Plattform, auf der Sie Dienstleistungen von Freelancern kaufen können.
                Registrieren Sie sich, loggen Sie sich ein und kontaktieren Sie den Freelancer direkt über Planetbots.
                Wenn Sie mit dem Freelancer und der erbrachten Dienstleistung zufrieden waren, bewerten Sie ihn direkt.
                Unser Bewertungssystem hilft anderen Kunden, die Zufriedenheit der bisherigen Freelancer einzusehen.
            </p>
            <div className="mx-auto max-w-7xl px-12 lg:px-16">
                <dl className="grid grid-cols-1 gap-x-8 gap-y-16 text-center lg:grid-cols-3">
                    {stats.map((stat) => (
                        <div key={stat.id} className="mx-auto flex max-w-xs flex-col gap-y-4">
                            <dt className="text-base leading-7 text-gray-600">{stat.name}</dt>
                            <dd className="order-first text-3xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
                                {stat.value}
                            </dd>
                        </div>
                    ))}
                </dl>
            </div>
        </div>
    );
};

export default About;