import React, { useState } from "react";
import {useNavigate, useParams} from "react-router-dom";
import newRequest from "../../utils/newRequest";
import Link from "@mui/material/Link";


const ResetPasswordPage = () => {
    const { token } = useParams();
    const navigate = useNavigate();
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [message, setMessage] = useState("");

    const handleResetPassword = async () => {
        if (password !== confirmPassword) {
            setMessage("Die Passwörter stimmen nicht überein.");
            return;
        }

        try {
            const response = await newRequest.post(`/auth/reset-password/${token}`, { password });
            setMessage(response.data.message);
            navigate("/login");
        } catch (error) {
            setMessage("Fehler beim Zurücksetzen des Passworts.");
        }
    };


    return (
        <div className="max-w-sm mx-auto">
            <div className="flex lg:flex-1">
                <a href="#" className="-m-1.5 p-1.5">
                    <Link className="navbar-link" href="/" underline="none">
                        <span className="navbar-text">planetbots</span>
                    </Link>
                </a>
            </div>
            <h1 className="text-2xl font-bold mb-4">Passwort wiederherstellen</h1>
            <div className="mb-4">
                <input type="text" id="password" placeholder="Passwort" value={password}
                       onChange={(e) => setPassword(e.target.value)}
                       className="w-full block mb-1 border border-gray-300 rounded px-3 py-2"/>
            </div>
            <div className="mb-4">
                <input type="text" id="confirmPassword" placeholder="Passwort" value={password}
                       onChange={(e) => setConfirmPassword(e.target.value)}
                       className="w-full block mb-1 border border-gray-300 rounded px-3 py-2"/>
            </div>
            <button
                className="bg-sky-500 text-white px-4 py-2 rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 mb-4"
                onClick={handleResetPassword}>
                Passwort wiederherstellen
            </button>
        </div>
    );
};

export default ResetPasswordPage;
