import React from 'react';

const TermsOfService = () => {
    return (
        <div className="container mx-auto py-8">
            <h2 className="text-3xl font-bold mb-4 text-center">Nutzungsbedingungen</h2>
            <div className="flex flex-wrap justify-center">
                Durch die Nutzung unserer Website erklären Sie sich mit diesen Nutzungsbedingungen sowie allen geltenden
                Gesetzen und Vorschriften einverstanden.<br/>
                Sie verpflichten sich, unsere Plattform nur für rechtmäßige Zwecke zu nutzen und keine Inhalte zu
                veröffentlichen, die gegen geltende Gesetze verstoßen.<br/>

                Dienstleistungen:<br/>

                Unsere Plattform ermöglicht es Freelancern, ihre Dienstleistungen
                anzubieten, und Kunden, diese Dienstleistungen zu erwerben. Wir übernehmen keine Verantwortung für die
                Qualität der von Freelancern erbrachten Dienstleistungen, streben jedoch an, allen Nutzern ein positives
                Erlebnis zu bieten.<br/>

                Zahlungen:<br/>

                Freelancer zahlen eine monatliche Abonnementgebühr über unsere Plattform. Zahlungen zwischen Kunden und Freelancern werden direkt zwischen ihnen abgewickelt, und wir sind nicht in diesen Transaktionen involviert. Wir behalten uns jedoch das Recht vor, Transaktionen für das Abonnement zu überprüfen und bei Bedarf zu stornieren oder zu erstatten.<br/>


                Haftungsbeschränkung:<br/>

                Wir haften nicht für direkte, indirekte, zufällige oder Folgeschäden, die sich aus der Nutzung unserer
                Website ergeben, einschließlich Daten- oder Einnahmeverlust.<br/>

                Änderungen der AGB:<br/>

                Wir behalten uns das Recht vor, diese AGB jederzeit ohne vorherige Ankündigung zu ändern. Ihre
                fortgesetzte Nutzung der Website nach solchen Änderungen stellt die Akzeptanz der geänderten Bedingungen
                dar.
            </div>
        </div>
    );
};

export default TermsOfService;