import React, { useState, useCallback } from 'react';
import { useNavigate } from "react-router-dom";
import { cards } from '../../data';

const Search = () => {
    const [input, setInput] = useState("");
    const navigate = useNavigate();

    const categories = cards.map(card => card.cat);

    // Synonyme für die Kategorien
    const synonyms = {
        ml: ['machine learning', 'ml'],
        dl: ['deep learning', 'dl'],
        neuronetze: ['neuronale netze', 'nn', 'neuro netze'],
        emm: ['email marketing', 'emm', 'email'],
        smm: ['smm', 'social', 'social media', 'social media marketing'],
        androidapp: ['and', 'android'],
        crossplattform: ['cross', 'crossplatt'],
        webdesign: ['ui', 'ux', 'uiux', 'uiux design']
        // Weitere Synonyme hinzufügen, falls nötig
    };

    const handleSearch = useCallback(() => {
        if (!input) return;

        const searchQuery = input.toLowerCase().replace(/\s+/g, '');

        // Überprüfen, ob der Suchbegriff mit einer Kategorie übereinstimmt
        let matchedCategory = categories.find(category =>
            category.toLowerCase().replace(/\s+/g, '').includes(searchQuery)
        );

        if (!matchedCategory) {
            // Suche in den Synonymen
            matchedCategory = Object.keys(synonyms).find(key =>
                synonyms[key].some(synonym =>
                    synonym.toLowerCase().replace(/\s+/g, '').includes(searchQuery)
                )
            );
        }

        // Wenn eine Übereinstimmung gefunden wurde, navigiere zur entsprechenden Kategorie
        if (matchedCategory) {
            navigate(`/services?cat=${encodeURIComponent(matchedCategory)}`);
        } else {
            // Wenn keine Übereinstimmung gefunden wurde, navigiere zur Suche mit dem Originalbegriff
            navigate(`/services?cat=${encodeURIComponent(input)}`);
        }
    }, [input, categories, navigate]);

    return (
        <div className="flex flex-col items-center w-full mt-14">
            <div className="relative w-full px-4">
                <input
                    type="text"
                    placeholder="Freelancer Jobs..."
                    className="pr-10 p-2 border rounded-full w-full text-white font-bold bg-sky-500 focus:outline-none placeholder-white sm:text-base md:text-lg"
                    onChange={(e) => setInput(e.target.value)}
                    onKeyDown={(e) => e.key === 'Enter' && handleSearch()}
                />
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                     className="absolute top-1/2 transform -translate-y-1/2 right-6 w-6 h-6 text-white cursor-pointer"
                     onClick={handleSearch}>
                    <path fillRule="evenodd"
                          d="M10.5 3.75a6.75 6.75 0 1 0 0 13.5 6.75 6.75 0 0 0 0-13.5ZM2.25 10.5a8.25 8.25 0 1 1 14.59 5.28l4.69 4.69a.75.75 0 1 1-1.06 1.06l-4.69-4.69A8.25 8.25 0 0 1 2.25 10.5Z"
                          clipRule="evenodd"/>
                </svg>
            </div>
        </div>
    );
};

export default Search;