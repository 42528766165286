import React, { useRef, useState, useEffect } from 'react';
import { useQuery } from "@tanstack/react-query";
import newRequest from "../../utils/newRequest";
import GigCard from "../../components/gigCard/GigCard";
import ServicesCategories from "../../components/servicesCategories/ServicesCategories.jsx";

const AllServices = () => {
    const [sort, setSort] = useState("sales");
    const minRef = useRef();
    const maxRef = useRef();

    // Fetch all gigs without filtering by category
    const { isLoading, error, data, refetch } = useQuery({
        queryKey: ["allGigs"],
        queryFn: () =>
            newRequest
                .get(
                    `/services?min=${minRef.current?.value || 0}&max=${maxRef.current?.value || 1000}&sort=${sort}`
                )
                .then((res) => res.data),
    });

    const applyFilter = () => {
        refetch();
    };

    useEffect(() => {
        refetch();
    }, [sort]);

    return (
        <div className="flex flex-col min-h-screen -mt-48">
            <div className="container mx-auto py-8">
                <ServicesCategories/>

                {/* Filter UI */}
                <div className="flex flex-col md:flex-row justify-between px-4 sm:px-8 my-4">
                    <input
                        ref={minRef}
                        type="number"
                        placeholder="Min. Preis"
                        className="w-full md:w-1/4 px-4 py-2 border rounded mb-2 md:mb-0"
                    />
                    <input
                        ref={maxRef}
                        type="number"
                        placeholder="Max. Preis"
                        className="w-full md:w-1/4 px-4 py-2 border rounded mb-2 md:mb-0"
                    />
                    <button
                        className="w-full md:w-auto bg-sky-500 text-white px-4 py-2 rounded-full hover:bg-sky-600"
                        onClick={applyFilter}
                    >
                        Filtern
                    </button>
                </div>

                {/* Loading state */}
                {isLoading && (
                    <div className="fixed inset-0 bg-white opacity-75 flex items-center justify-center">
                        <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-sky-500"></div>
                    </div>
                )}

                {/* Error state */}
                {error && (
                    <div className="fixed inset-0 bg-white opacity-75 flex items-center justify-center">
                        <span className="text-red-500">Etwas ist schiefgelaufen, bitte versuchen Sie es erneut</span>
                    </div>
                )}

                {/* Display gigs */}
                <div className="flex flex-wrap -mx-4 px-4 mt-8 sm:px-8">
                    {Array.isArray(data) && data.length > 0 ? (
                        data.map((service) => (
                            <div
                                key={service._id}
                                className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 px-4 mb-4"
                            >
                                <GigCard item={service} />
                            </div>
                        ))
                    ) : (
                        !isLoading && !error && (
                            <span className="text-center text-gray-900 text-lg w-full block mt-4">
                                Noch keine Uploads. Seien Sie der Erste.<br />
                                <a href="/add">
                                    <button className="mt-4 bg-sky-500 text-white py-2 px-4 rounded-full hover:bg-sky-600">
                                        Hier Angebot veröffentlichen
                                    </button>
                                </a>
                            </span>
                        )
                    )}
                </div>
            </div>
        </div>
    );
};

export default AllServices;
