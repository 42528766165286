import React, { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import newRequest from "../../utils/newRequest";
import { Link } from "react-router-dom";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { Modal } from "@mui/material";

const Orders = () => {
    const [statusUpdatingId, setStatusUpdatingId] = useState(null);
    const [isDeleteSuccessful, setIsDeleteSuccessful] = useState(false);
    const [newStatus, setNewStatus] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [openConfirmModal, setOpenConfirmModal] = useState(false);
    const [orderToDelete, setOrderToDelete] = useState(null);
    const queryClient = useQueryClient();

    const currentUser = JSON.parse(localStorage.getItem("currentUser"));

    const { isLoading, error, data } = useQuery({
        queryKey: ["orders", { isSeller: true }],
        queryFn: () => newRequest.get(`/orders?isSeller=true`).then((res) => res.data),
    });

    const updateOrderStatus = useMutation({
        mutationFn: (order) => newRequest.patch(`/orders`, { orderId: order._id, status: newStatus }),
        onSuccess: () => {
            queryClient.invalidateQueries(["orders"]);
            setStatusUpdatingId(null);
            setNewStatus(null);
            setErrorMessage(null);
        },
        onError: () => {
            setErrorMessage("Sie können den Status nur mit einem erweiterten Plan ändern.");
        },
    });

    const deleteOrderMutation = useMutation({
        mutationFn: (orderId) => newRequest.delete(`/orders/${orderId}`),
        onSuccess: () => {
            queryClient.invalidateQueries(["orders"]);
            setIsDeleteSuccessful(true);
            setOpenConfirmModal(false);
            setTimeout(() => setIsDeleteSuccessful(false), 3000); // Erfolgsnachricht nach 3 Sekunden ausblenden
        },
    });

    const handleDeleteOrder = (orderId) => {
        setOrderToDelete(orderId);
        setOpenConfirmModal(true);
    };

    const handleConfirmDelete = () => {
        if (orderToDelete) {
            deleteOrderMutation.mutate(orderToDelete);
        }
    };

    const handleConfirmClose = () => {
        setOpenConfirmModal(false);
        setOrderToDelete(null);
    };

    const truncateTitle = (title, maxLength) => {
        if (title.length > maxLength) {
            return title.substring(0, maxLength) + "...";
        }
        return title;
    };

    const handleStatusChange = (order) => {
        if (currentUser.plan === 'basic') {
            setErrorMessage("Sie können den Status nur mit einem erweiterten Plan ändern.");
            return;
        }

        if (newStatus) {
            updateOrderStatus.mutate(order);
        }
    };

    return (
        <div className="container mx-auto px-4 sm:px-6 lg:px-8 max-w-screen-xl py-4">
            <div className="text-center mb-4">
                <h1 className="text-xl sm:text-2xl md:text-3xl font-bold">Meine Aufträge</h1>
                {isDeleteSuccessful && (
                    <div className="fixed top-4 right-4 bg-green-600 text-white px-4 py-2 rounded-md shadow-lg">
                        Auftrag wurde erfolgreich entfernt
                    </div>
                )}
                {errorMessage && (
                    <div className="flex justify-center items-center mt-2">
                        <svg
                            className="h-6 w-6 text-red-500"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                        <p className="text-red-500 text-sm ml-2">{errorMessage}</p>
                    </div>
                )}
            </div>
            <p className="text-base sm:text-lg md:text-xl text-gray-600 mb-8 text-center">Managen Sie Ihre Aufträge</p>
            {isLoading ? (
                <div className="flex justify-center items-center h-64">
                    <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-blue-600"></div>
                </div>
            ) : error ? (
                <p className="text-red-500 text-center">Etwas ist schiefgelaufen, bitte versuchen Sie es erneut</p>
            ) : data.length === 0 ? (
                <p className="text-gray-600 text-center">Es sieht so aus, als hätten Sie noch keine Aufträge erhalten.</p>
            ) : (
                <div className="overflow-x-auto">
                    <table className="min-w-full bg-white divide-y divide-gray-200">
                        <thead className="bg-gray-100">
                        <tr>
                            <th className="py-3 px-4 text-left text-sm font-medium text-gray-900">Bild</th>
                            <th className="py-3 px-4 text-left text-sm font-medium text-gray-900">Titel</th>
                            <th className="py-3 px-4 text-left text-sm font-medium text-gray-900">Preis</th>
                            <th className="py-3 px-4 text-left text-sm font-medium text-gray-900">Benutzername</th>
                            <th className="py-3 px-4 text-left text-sm font-medium text-gray-900">Status</th>
                            <th className="py-3 px-4 text-left text-sm font-medium text-gray-900">Aktionen</th>
                        </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                        {data.map((order) => (
                            <tr key={order._id}>
                                <td className="py-4 px-4">
                                    <img
                                        className="w-16 h-16 object-cover rounded-full"
                                        src={order.img}
                                        alt="order_img"
                                    />
                                </td>
                                <td className="py-4 px-4">
                                    <Link to={`/service/${order.gigId}`} className="text-blue-600 hover:underline">
                                        {truncateTitle(order.title, 20)}
                                    </Link>
                                </td>
                                <td className="py-4 px-4 text-blue-500 font-semibold">{order.price} €</td>
                                <td className="py-4 px-4 text-gray-600">{order.buyerId?.username}</td>
                                <td className="py-4 px-4">
                                    {statusUpdatingId === order._id ? (
                                        <select
                                            value={newStatus}
                                            onChange={(e) => setNewStatus(e.target.value)}
                                            className="border border-gray-300 rounded-md p-1"
                                        >
                                            <option value="" disabled>
                                                Status auswählen
                                            </option>
                                            <option value="in_progress">In Bearbeitung</option>
                                            <option value="completed">Fertig</option>
                                        </select>
                                    ) : (
                                        <span
                                            className={`inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium ${
                                                order.isCompleted
                                                    ? "bg-green-100 text-green-800"
                                                    : "bg-yellow-100 text-yellow-800"
                                            }`}
                                        >
                                        {order.isCompleted ? "Fertig" : "In Bearbeitung"}
                                    </span>
                                    )}
                                </td>
                                <td className="py-4 px-4">
                                    {currentUser._id === order.sellerId && currentUser.plan !== 'basic' && (
                                        <>
                                            {statusUpdatingId === order._id ? (
                                                <button
                                                    onClick={() => handleStatusChange(order)}
                                                    className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700"
                                                >
                                                    Speichern
                                                </button>
                                            ) : (
                                                <button
                                                    onClick={() => setStatusUpdatingId(order._id)}
                                                    className="text-blue-500 hover:text-blue-700"
                                                >
                                                    Status bearbeiten
                                                </button>
                                            )}
                                        </>
                                    )}
                                    <button
                                        onClick={() => handleDeleteOrder(order._id)}
                                        className="text-red-500 hover:text-red-700 ml-2"
                                    >
                                        <DeleteForeverOutlinedIcon/>
                                    </button>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            )}

            {/* Bestätigungsmodal */}
            <Modal
                open={openConfirmModal}
                onClose={handleConfirmClose}
                aria-labelledby="confirm-modal-title"
                aria-describedby="confirm-modal-description"
                className="flex items-center justify-center"
            >
                <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm mx-auto">
                    <h2 className="text-lg font-semibold mb-4">Sind Sie sicher, dass Sie den Auftrag löschen möchten?</h2>
                    <p className="text-gray-700 mb-6">Der Auftrag wird dauerhaft gelöscht und kann nicht wiederhergestellt werden.</p>
                    <div className="flex justify-end space-x-4">
                        <button
                            onClick={handleConfirmDelete}
                            className="bg-red-600 text-white px-4 py-2 rounded-md hover:bg-red-700"
                        >
                            Ja
                        </button>
                        <button
                            onClick={handleConfirmClose}
                            className="bg-gray-300 text-gray-800 px-4 py-2 rounded-md hover:bg-gray-400"
                        >
                            Nein
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
);
};

export default Orders;
