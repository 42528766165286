import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FaBrain, FaLaptopCode, FaPalette, FaBullhorn, FaMobileAlt, FaDatabase } from 'react-icons/fa';
import {FaPencil} from "react-icons/fa6";


const ServicesCategories = () => {
    const [isOpen, setIsOpen] = useState(null);
    const dropdownRef = useRef(null);

    // Toggle menu
    const toggleMenu = useCallback((menu) => {
        setIsOpen(prev => (prev === menu ? null : menu));
    }, []);

    // Handle click outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(null);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="container mx-auto py-8 px-4 sm:px-6 lg:px-8">
            <div className="text-center lg:text-left">
                <h1 className="text-2xl sm:text-3xl lg:text-4xl font-extrabold text-gray-900 mb-4">
                    Hier finden Sie alle Kategorien und Jobangebote für Freelancer, die wir zurzeit anbieten.
                </h1>
                <span className="text-lg sm:text-xl lg:text-2xl text-gray-700 mb-4 block">
                    Zögern Sie bitte nicht, uns zu kontaktieren, wenn Sie ein Jobangebot nicht finden oder ein anderes Anliegen haben.
                </span>
                <p className="text-base sm:text-lg lg:text-xl text-gray-600">
                    Schreiben Sie uns eine E-Mail oder kommen Sie auf unseren Discord-Server. Sie finden unsere Kontaktdaten unter Support.
                </p>
        <div className="relative grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 mt-8 p-4" ref={dropdownRef}>
            {categories.map((category) => (
                <div key={category.id} className="relative">
                    <button
                        onClick={() => toggleMenu(category.id)}
                        className={`w-full px-6 py-3 rounded-lg text-white bg-gradient-to-r from-blue-500 to-indigo-600 hover:from-blue-600 hover:to-indigo-700 transition-all duration-300 focus:outline-none focus:ring-4 focus:ring-indigo-300 flex items-center justify-between`}
                    >
                        <div className="flex items-center">
                            {category.icon} {/* Hier wird das Icon eingefügt */}
                            <span className="ml-2">{category.title}</span>
                        </div>
                        <span>&#x25BC;</span>
                    </button>
                    {isOpen === category.id && (
                        <div className="absolute mt-2 w-full bg-white border border-gray-200 rounded-lg shadow-lg z-50">
                            {category.links.map((link) => (
                                <a
                                    key={link.id}
                                    href={link.href}
                                    className="block px-4 py-2 text-gray-700 hover:bg-gray-100 transition-colors duration-200"
                                >
                                    {link.text}
                                </a>
                            ))}
                        </div>
                    )}
                </div>
            ))}
        </div>
        </div>
        </div>
    );
};

const categories = [
    {
        id: 'ai',
        title: 'Künstliche Intelligenz',
        icon: <FaBrain className="mr-2"/>, // Icon wird hier definiert
        links: [
            {id: 'ml', href: '/services?cat=ml', text: 'Machine Learning'},
            {id: 'dl', href: '/services?cat=dl', text: 'Deep Learning'},
            {id: 'neuronetze', href: '/services?cat=neuronetze', text: 'Neuronale Netze'},
            {id: 'chatbots', href: '/services?cat=chatbots', text: 'Chatbots'},
        ],
    },
    {
        id: 'website',
        title: 'Website',
        icon: <FaLaptopCode className="mr-2"/>,
        links: [
            {id: 'wordpress', href: '/services?cat=wordpress', text: 'Wordpress'},
            {id: 'landingpage', href: '/services?cat=landingpage', text: 'Landing Page'},
            {id: 'frontend', href: '/services?cat=frontend', text: 'Frontend'},
            {id: 'backend', href: '/services?cat=backend', text: 'Backend'},
            {id: 'fullstack', href: '/services?cat=fullstack', text: 'Fullstack'},
            {id: 'shopifycustom', href: '/services?cat=shopifycustom', text: 'Shopify'},
            {id: 'speedoptimization', href: '/services?cat=speedoptimization', text: 'Speed Optimierung'},
        ],
    },
    {
        id: 'design',
        title: 'Design',
        icon: <FaPalette className="mr-2"/>,
        links: [
            {id: 'webdesign', href: '/services?cat=webdesign', text: 'UI/UX Design'},
            {id: 'logodesign', href: '/services?cat=logodesign', text: 'Logo Design'},
        ],
    },
    {
        id: 'socialmedia',
        title: 'Social Media',
        icon: <FaBullhorn className="mr-2"/>,
        links: [
            {id: 'youtube', href: '/services?cat=youtube', text: 'YouTube Videos'},
            {id: 'shorts', href: '/services?cat=shorts', text: 'Shorts' },
            { id: 'thumbnail', href: '/services?cat=thumbnail', text: 'Thumbnails' },
            { id: 'tiktok', href: '/services?cat=tiktok', text: 'TikTok' },
        ],
    },
    {
        id: 'marketing',
        title: 'Marketing',
        icon: <FaBullhorn className="mr-2" />, // Beispiel: Verwenden des gleichen Icons wie Social Media
        links: [
            { id: 'seo', href: '/services?cat=seo', text: 'SEO' },
            { id: 'smm', href: '/services?cat=smm', text: 'Social Media Marketing' },
            { id: 'emm', href: '/services?cat=emm', text: 'E-Mail Marketing' },
        ],
    },
    {
        id: 'contentservice',
        title: 'Content Service',
        icon: <FaPencil className="mr-2" />, // Beispiel: Verwenden des gleichen Icons wie Design
        links: [
            { id: 'blogs', href: '/services?cat=blogs', text: 'Blog' },
            { id: 'translation', href: '/services?cat=translation', text: 'Übersetzungen' },
        ],
    },
    {
        id: 'brand',
        title: 'Brand',
        icon: <FaPalette className="mr-2" />, // Beispiel: Verwenden des gleichen Icons wie Design
        links: [
            { id: 'branding', href: '/services?cat=branding', text: 'Branding' },
        ],
    },
    {
        id: 'app',
        title: 'App Entwicklung',
        icon: <FaMobileAlt className="mr-2" />,
        links: [
            { id: 'iosapp', href: '/services?cat=iosapp', text: 'iOS App' },
            { id: 'androidapp', href: '/services?cat=androidapp', text: 'Android App' },
            { id: 'crossplattform', href: '/services?cat=crossplattform', text: 'Crossplattform App' },
            { id: 'pwa', href: '/services?cat=pwa', text: 'PWA App' },
        ],
    },
    {
        id: 'software',
        title: 'Software',
        icon: <FaLaptopCode className="mr-2" />,
        links: [
            { id: 'bugfixing', href: '/services?cat=bugfixing', text: 'Bug Fixes' },
            { id: 'qareviews', href: '/services?cat=qareviews', text: 'Q&A Reviews' },
            { id: 'usertests', href: '/services?cat=usertests', text: 'User Tests' },
        ],
    },
    {
        id: 'db',
        title: 'Datenbanken',
        icon: <FaDatabase className="mr-2" />,
        links: [
            { id: 'mysql', href: '/services?cat=mysql', text: 'MySQL' },
            { id: 'mongodb', href: '/services?cat=mongodb', text: 'MongoDB' },
        ],
    },
];

export default ServicesCategories;
