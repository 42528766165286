import React from 'react';

const PropertyClaims = () => {
    return (
        <div className="container mx-auto py-8">
            <h2 className="text-3xl font-bold mb-4 text-center">Ansprüche auf geistiges Eigentum</h2>
            <div className="flex flex-wrap justify-center">
                planetbots<br/>
                Adresse: Am Rund 6<br/>
                PLZ Stadt: 23566 Lübeck<br/>
                E-Mail: support@planetbots.de<br/>
            </div>
        </div>
    );
};

export default PropertyClaims;