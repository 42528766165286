import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import Link from "@mui/material/Link";
import newRequest from "../../utils/newRequest.js";

function Register() {
    const [error, setError] = useState(null);
    const [user, setUser] = useState({
        username: "",
        email: "",
        password: "",
        isSeller: false,
        desc: "",
    });

    const navigate = useNavigate();

    const validateEmail = (email) => {
        const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        return emailPattern.test(email);
    };

    const handleChange = (e) => {
        setUser({...user, [e.target.name]: e.target.value});
        if (e.target.name === "email") {
            setError(validateEmail(e.target.value) ? null : "Ungültige E-Mail Adresse");
        }
    };

    const handleSeller = () => {
        setUser({...user, isSeller: !user.isSeller});
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const {username, email, password} = user;

        if (!username) {
            setError("Bitte tragen Sie einen Benutzernamen ein");
            return;
        }

        if (!validateEmail(email)) {
            setError("Bitte tragen Sie eine gültige E-Mail Adresse ein");
            return;
        }

        if (!password || password.length < 6) {
            setError("Das Passwort muss mindesten 6 Zeichen lang sein");
            return;
        }

        setError(null);

        try {
            const userData = {...user};
            if (!userData.isSeller) delete userData.isSeller;
            if (!userData.desc) delete userData.desc;

            const response = await newRequest.post("/auth/register", userData);

            // E-Mail-Adresse im localStorage speichern
            localStorage.setItem('emailForVerification', email);

            navigate('/verify-email'); // Nach der Registrierung zur Verifizierungsseite navigieren
        } catch (err) {
            setError("Die Registrierung ist fehlgeschlagen. Bitte versuchen Sie es erneut");
        }
    };

    return (
        <div className="max-w-md mx-auto bg-white p-8 rounded-xl shadow-lg mt-10">
            <div className="flex justify-center mb-6">
                <Link href="/" underline="none">
                    <h1 className="text-2xl font-bold text-blue-600">planetbots</h1>
                </Link>
            </div>
            <h2 className="text-xl font-semibold text-gray-800 text-center mb-6">Registrieren Sie sich</h2>
            {error && <p className="text-red-500 text-center mb-4 animate-bounce">{error}</p>}
            <form onSubmit={handleSubmit} className="space-y-4">
                <div className="space-y-2">
                    <input
                        type="text"
                        name="username"
                        placeholder="Benutzername"
                        onChange={handleChange}
                        className="w-full p-3 border border-gray-300 rounded-full focus:outline-none focus:border-blue-500 transition-colors"
                    />
                    <input
                        type="text"
                        name="email"
                        placeholder="E-Mail Adresse"
                        onChange={handleChange}
                        className="w-full p-3 border border-gray-300 rounded-full focus:outline-none focus:border-blue-500 transition-colors"
                    />
                    <input
                        type="password"
                        name="password"
                        placeholder="Passwort"
                        onChange={handleChange}
                        className="w-full p-3 border border-gray-300 rounded-full focus:outline-none focus:border-blue-500 transition-colors"
                    />
                </div>
                <button
                    type="submit"
                    className='w-full py-3 bg-sky-500 text-white rounded-full flex items-center justify-center hover:bg-sky-600 focus:outline-none focus:ring-4 focus:ring-blue-300 transition-colors'
                >
                    Registrieren
                </button>

            </form>
            <p className="text-center mt-4 text-lg">
                Haben Sie schon einen Account?{" "}
                <Link href="/login" className="text-blue-500 underline">
                    Hier Anmelden
                </Link>
            </p>
            <div className="flex items-center justify-center mt-6">
                <button
                    className={`relative flex-shrink-0 h-6 w-12 border-2 rounded-full cursor-pointer transition-colors duration-200 ease-in-out focus:outline-none ${
                        user.isSeller ? 'bg-green-600' : 'bg-gray-300'
                    }`}
                    onClick={handleSeller}
                >
                    <span
                        className={`absolute left-0 inset-y-0 w-6 h-6 bg-white border-2 rounded-full transform transition-transform duration-200 ease-in-out ${
                            user.isSeller ? 'translate-x-6' : 'translate-x-0'
                        }`}
                    ></span>
                </button>
                <span className="ml-3 text-gray-700">
                    {user.isSeller ? "Du bist ein Freelancer" : "Werde ein Freelancer"}
                </span>
            </div>
            {user.isSeller && (
                <div className="mt-4">
                    <label htmlFor="desc" className="block text-lg font-medium text-gray-700">
                        Beschreibe dich als Freelancer
                    </label>
                    <textarea
                        name="desc"
                        placeholder="Beschreibe dich..."
                        onChange={handleChange}
                        className="mt-2 p-3 w-full border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 transition-colors"
                    ></textarea>
                </div>
            )}
        </div>
    );
}

export default Register;
