import React from 'react';

const Feedback = () => {
    return (
        <div className="container mx-auto py-8">
            dew
        </div>
    );
};

export default Feedback;