import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import newRequest from "../../utils/newRequest";
import '../../index.css'

import Link from '@mui/material/Link';
import PersonIcon from '@mui/icons-material/Person';
import SellIcon from '@mui/icons-material/Sell';
import AddIcon from '@mui/icons-material/Add';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import MessageIcon from '@mui/icons-material/Message';
import LogoutIcon from '@mui/icons-material/Logout';
import {Dialog} from '@headlessui/react'
import {Bars3Icon, XMarkIcon} from '@heroicons/react/24/outline'
import {CardMembership} from "@mui/icons-material";
import EuroSymbolIcon from '@mui/icons-material/EuroSymbol';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import HeroSections from "../heroSection/HeroSections.jsx";

function Navbar() {
    const [active, setActive] = useState(false);
    const [open, setOpen] = useState(false);
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
    const [isRegisterLoading, setIsRegisterLoading] = useState(false);
    const [isLoginLoading, setIsLoginLoading] = useState(false);

    const {pathname} = useLocation();

    const isActive = () => {
        setActive(window.scrollY > 0);
    };

// Debounce-Funktion
    const debounce = (func, wait) => {
        let timeout;
        return function (...args) {
            clearTimeout(timeout);
            timeout = setTimeout(() => func.apply(this, args), wait);
        };
    };

    useEffect(() => {
        const handleScroll = debounce(isActive, 100); // Debounce auf 100ms setzen
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const currentUser = JSON.parse(localStorage.getItem("currentUser"));

    const navigate = useNavigate();

    const navigation = useMemo(() => [
        {name: 'Meine Angebote', href: '/myservices'},
        {name: 'Angebot hinzufügen', href: '/add'},
        {name: 'Meine Bestellungen', href: '/orders'},
        {name: 'Nachrichten', href: '/messages'},
        {name: 'Preise', href: '/price'}
    ], []);


    const handleLogout = useCallback(async () => {
        try {
            await newRequest.post("/auth/logout");
            localStorage.removeItem("currentUser"); // Verwende removeItem anstelle von setItem(null)
            navigate("/");
        } catch (err) {
            console.error(err);
        }
    }, [navigate]);

    const location = useLocation();
    const isHomePage = location.pathname === "/";

    const isServicePage = (path) => {
        const servicePathPattern = /^\/service\/[\w-]+$/; // Anpassung der Regex, falls nötig
        return servicePathPattern.test(path);
    };

    const isBlogPost = (path) => {
        const blogPathPattern = /^\/post\/[\w-]+$/; // Anpassung der Regex, falls nötig
        return blogPathPattern.test(path);
    };

    const isMessagesPage = (path) => {
        const messagesPathPattern = /^\/messages\/[\w-]+$/; // Anpassung der Regex, falls nötig
        return messagesPathPattern.test(path);
    };


    return (
        <div
            className={`${active || pathname !== "/" ? "navbar active" : "navbar"} relative -top-14 inset-x-0 z-50 container mx-auto`}>
            <header className="relative inset-x-0 z-50 mx-auto">
                <nav className="flex items-center justify-between lg:px-4" aria-label="Global">
                    <div className="flex lg:flex-1">
                        <a href="#" className="-m-1.5 p-1.5">
                            <Link className="navbar-link" href="/" underline="none">
                                <span className="text-3xl">planetbots</span>
                            </Link>
                        </a>
                    </div>
                    <div className="flex lg:hidden">
                        <button
                            type="button"
                            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                            onClick={() => setMobileMenuOpen(true)}
                        >
                            <span className="sr-only">Open main menu</span>
                            <Bars3Icon className="h-6 w-6" aria-hidden="true"/>
                        </button>
                    </div>
                    <div className="hidden lg:flex lg:gap-x-12">
                        {currentUser ? (
                            <>
                                {navigation.map((item) => (
                                    <a key={item.name} href={item.href}
                                       className="text-lg font-semibold leading-6 text-gray-900 hover:bg-gray-300">
                                        {item.name}
                                    </a>
                                ))}
                            </>
                        ) : (
                            <>
                                <a href="/#about"
                                   className="text-lg font-semibold leading-6 text-gray-900 hover:text-gray-700">Über
                                    uns</a>
                                <a href="/#services"
                                   className="text-lg font-semibold leading-6 text-gray-900 hover:text-gray-700">Freelance
                                    Jobs</a>
                                <a href="/#blog"
                                   className="text-lg font-semibold leading-6 text-gray-900 hover:text-gray-700">Blog</a>
                                <a href="/#newsletter"
                                   className="text-lg font-semibold leading-6 text-gray-900 hover:text-gray-700">Newsletter</a>
                                <a href="/price"
                                   className="text-lg font-semibold leading-6 text-gray-900 hover:text-gray-700">Preise</a>
                            </>
                        )}
                    </div>
                    <div className="hidden lg:flex lg:flex-1 lg:justify-end">
                    {currentUser ? (
                            <div className="relative">
                                <button onClick={() => setOpen(!open)}
                                        className="flex items-center focus:outline-none">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                         strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round"
                                              d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"/>
                                    </svg>
                                    <span
                                        className="text-sm font-semibold text-gray-900">{currentUser?.username}</span>
                                </button>
                                {open && (
                                    <div className="absolute w-48 bg-white rounded-md shadow-lg py-1 right-0">
                                        {currentUser.isSeller && (
                                            <>
                                                <Link href={`/profile/${currentUser?.username}`}
                                                      underline="none">
                                                    <a className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                                            <span className="flex items-center">
                                                <PersonIcon className="w-4 h-4 mr-2"/>
                                                Profil
                                            </span>
                                                    </a>
                                                </Link>
                                                <Link href="/mysubscription" underline="none">
                                                    <a className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                                            <span className="flex items-center">
                                                <CardMembership className="w-4 h-4 mr-2"/>
                                                Mein Abonnement
                                            </span>
                                                    </a>
                                                </Link>
                                                <Link href="/myservices" underline="none">
                                                    <a className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                                            <span className="flex items-center">
                                                <SellIcon className="w-4 h-4 mr-2"/>
                                                Meine Angebote
                                            </span>
                                                    </a>
                                                </Link>
                                                <Link href="/add" underline="none">
                                                    <a className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                                            <span className="flex items-center">
                                                <AddIcon className="w-4 h-4 mr-2"/>
                                                Angebot hinzufügen
                                            </span>
                                                    </a>
                                                </Link>
                                            </>
                                        )}
                                        <Link href="/orders" underline="none">
                                            <a className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                                    <span className="flex items-center">
                                        <ShoppingBagIcon className="w-4 h-4 mr-2"/>
                                        Meine Bestellungen
                                    </span>
                                            </a>
                                        </Link>
                                        <Link href="/messages" underline="none">
                                            <a className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                                    <span className="flex items-center">
                                        <MessageIcon className="w-4 h-4 mr-2"/>
                                        Nachrichten
                                    </span>
                                            </a>
                                        </Link>
                                        <Link href="/cart" underline="none">
                                            <a className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                                            <span className="flex items-center">
                                                <AddShoppingCartIcon className="w-4 h-4 mr-2"/>
                                                Mein Warenkorb
                                            </span>
                                            </a>
                                        </Link>
                                        <Link href="/price" underline="none">
                                            <a className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                                    <span className="flex items-center">
                                        <EuroSymbolIcon className="w-4 h-4 mr-2"/>
                                        Preise
                                    </span>
                                            </a>
                                        </Link>
                                        <button onClick={handleLogout}
                                                className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 focus:outline-none">
                                <span className="flex items-center">
                                    <LogoutIcon className="w-4 h-4 mr-2"/>
                                    Abmelden
                                </span>
                                        </button>
                                    </div>
                                )}
                            </div>
                        ) : (
                        <div>
                            <Link href="/login" className="mr-4">
                                <button
                                    className="inline-flex items-center justify-center mr-4"
                                    disabled={isLoginLoading}
                                    onClick={() => setIsLoginLoading(true)}
                                >
                                    Anmelden
                                </button>
                            </Link>
                            <Link href="/register">
                                <button
                                    className="bg-sky-500 text-white border py-2 px-4 rounded-full hover:bg-sky-600 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-opacity-50 inline-flex items-center justify-center"
                                    disabled={isRegisterLoading}
                                    onClick={() => setIsRegisterLoading(true)}
                                >
                                    Registrieren
                                </button>
                            </Link>
                        </div>
                    )}
                    </div>
                </nav>
                <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
                    <div className="fixed inset-0 z-50"/>
                    <Dialog.Panel
                        className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                        <div className="flex items-center justify-between">
                            <a href="#" className="-m-1.5 p-1.5">
                                <span className="sr-only">planetbots</span>
                            </a>
                            <button
                                type="button"
                                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                                onClick={() => setMobileMenuOpen(false)}
                            >
                                <span className="sr-only">Close menu</span>
                                <XMarkIcon className="h-6 w-6" aria-hidden="true"/>
                            </button>
                        </div>
                        <div className="mt-6 flow-root">
                            <div className="-my-6 divide-y divide-gray-500/10">
                                <div className="space-y-2 py-6">
                                    {currentUser ? (
                                        <>
                                            <a
                                                href={`/profile/${currentUser?.username}`}
                                                className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                            >
                            <span className="flex items-center">
                                <PersonIcon className="w-4 h-4 mr-2"/>
                                Profil
                            </span>
                                            </a>
                                            <a
                                                href="/mysubscription"
                                                className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                            >
                            <span className="flex items-center">
                                <CardMembership className="w-4 h-4 mr-2"/>
                                Mein Abonnement
                            </span>
                                            </a>
                                            <a
                                                href="/myservices"
                                                className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                            >
                            <span className="flex items-center">
                                <SellIcon className="w-4 h-4 mr-2"/>
                                Meine Angebote
                            </span>
                                            </a>
                                            <a
                                                href="/add"
                                                className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                            >
                            <span className="flex items-center">
                                <AddIcon className="w-4 h-4 mr-2"/>
                                Angebot hinzufügen
                            </span>
                                            </a>
                                            <a href="/orders"
                                               className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                                    <span className="flex items-center">
                                        <ShoppingBagIcon className="w-4 h-4 mr-2"/>
                                        Meine Bestellungen
                                    </span>
                                            </a>
                                            <a href="/messages"
                                               className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                                    <span className="flex items-center">
                                        <MessageIcon className="w-4 h-4 mr-2"/>
                                        Nachrichten
                                    </span>
                                            </a>
                                            <a href="/cart"
                                               className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                                            <span className="flex items-center">
                                                <AddShoppingCartIcon className="w-4 h-4 mr-2"/>
                                                Mein Warenkorb
                                            </span>
                                            </a>
                                            <a href="/price"
                                               className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                                    <span className="flex items-center">
                                        <EuroSymbolIcon className="w-4 h-4 mr-2"/>
                                        Preise
                                    </span>
                                            </a>

                                            <button
                                                onClick={handleLogout}
                                                className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                            >
                            <span className="flex items-center">
                                <LogoutIcon className="w-4 h-4 mr-2"/>
                                Abmelden
                            </span>
                                            </button>
                                        </>
                                    ) : (
                                        <>
                                            <a href="/#about"
                                               className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                            >
                                                Über uns
                                            </a>
                                            <a href="/#services"
                                               className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                            >
                                                Freelancer Jobs
                                            </a>
                                            <a href="/#blog"
                                               className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                            >
                                                Blog
                                            </a>
                                            <a href="/#newsletter"
                                               className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                            >
                                                Newsletter
                                            </a>
                                            <a href="/price"
                                               className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                            >
                                                Preise
                                            </a>

                                            <a
                                                href="/login"
                                                className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                            >
                                                <button
                                                    className="w-full py-2 px-4 inline-flex items-center justify-center"
                                                    disabled={isLoginLoading}
                                                    onClick={() => setIsLoginLoading(true)}
                                                >
                                                    {isLoginLoading ? (
                                                        <>
                                                            <svg
                                                                className="animate-spin h-5 w-5 mr-3 text-white"
                                                                viewBox="0 0 24 24">
                                                                <circle className="opacity-25" cx="12" cy="12"
                                                                        r="10"
                                                                        stroke="currentColor"
                                                                        strokeWidth="4"></circle>
                                                                <path className="opacity-75" fill="currentColor"
                                                                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                            </svg>
                                                            Processing...
                                                        </>
                                                    ) : (
                                                        "Anmelden"
                                                    )}
                                                </button>
                                            </a>
                                            <a
                                                href="/register"
                                                className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                            >
                                                <button
                                                    className="w-full bg-sky-500 text-white border py-2 px-4 rounded-full focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-opacity-50 inline-flex items-center justify-center"
                                                    disabled={isRegisterLoading}
                                                    onClick={() => setIsRegisterLoading(true)}
                                                >
                                                    {isRegisterLoading ? (
                                                        <>
                                                            <svg
                                                                className="animate-spin h-5 w-5 mr-3 text-white"
                                                                viewBox="0 0 24 24">
                                                                <circle className="opacity-25" cx="12" cy="12"
                                                                        r="10"
                                                                        stroke="currentColor"
                                                                        strokeWidth="4"></circle>
                                                                <path className="opacity-75" fill="currentColor"
                                                                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                            </svg>
                                                            Processing...
                                                        </>
                                                    ) : (
                                                        "Registrieren"
                                                    )}
                                                </button>
                                            </a>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </Dialog.Panel>
                </Dialog>
                {pathname !== '/allServices' &&
                    pathname !== '/services' &&
                    !isServicePage(pathname) &&
                    pathname !== '/myservices' &&
                    pathname !== '/add' &&
                    pathname !== '/orders' &&
                    pathname !== '/messages' &&
                    pathname !== '/message' &&
                    pathname !== '/price' &&
                    pathname !== `/profile/${currentUser?.username}` &&
                    pathname !== '/mysubscription' &&
                    pathname !== '/cart' &&
                    pathname !== '/about' &&
                    pathname !== '/privacyPolicy' &&
                    pathname !== '/termsofservice' &&
                    pathname !== '/propertyClaims' &&
                    pathname !== '/allblogs' &&
                    !isBlogPost(pathname) &&
                    !isMessagesPage(pathname) && (
                        <>
                            <HeroSections/>
                        </>
                    )}
            </header>
        </div>
    );
}

export default Navbar;