import React, {useEffect, useRef, useState} from "react";
import GigCard from "../../components/gigCard/GigCard";
import {useQuery} from "@tanstack/react-query";
import newRequest from "../../utils/newRequest";
import {useLocation} from "react-router-dom";

function Gigs() {
    const [sort, setSort] = useState("sales");
    const [open, setOpen] = useState(false);
    const minRef = useRef();
    const maxRef = useRef();

    const {search} = useLocation();

    const {isLoading, error, data, refetch} = useQuery({
        queryKey: ["gigs"],
        queryFn: () =>
            newRequest
                .get(
                    `/services${search}&min=${minRef.current?.value || 0}&max=${maxRef.current?.value || 1000}&sort=${sort}`
                )
                .then((res) => res.data),
    });

    const reSort = (type) => {
        setSort(type);
        setOpen(false);
    };

    useEffect(() => {
        refetch();
    }, [sort]);

    const apply = () => {
        refetch();
    };

    return (
        <div className="container mx-auto py-4 mb-96 -mt-28 relative">
            {isLoading && (
                <div className="fixed inset-0 bg-white opacity-75 flex items-center justify-center">
                    <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-sky-500"></div>
                </div>
            )}

            {error && (
                <div className="fixed inset-0 bg-white opacity-75 flex items-center justify-center">
                    <span className="text-red-500">Etwas ist schiefgelaufen, bitte versuchen Sie es erneut</span>
                </div>
            )}

            <div className="flex flex-col md:flex-row justify-between px-4 sm:px-8">
                <input
                    ref={minRef}
                    type="number"
                    placeholder="Min. Preis"
                    className="w-full md:w-1/4 px-4 py-2 border rounded mb-2 md:mb-0"
                />
                <input
                    ref={maxRef}
                    type="number"
                    placeholder="Max. Preis"
                    className="w-full md:w-1/4 px-4 py-2 border rounded mb-2 md:mb-0"
                />
                <button
                    className="w-full md:w-auto bg-sky-500 text-white px-4 py-2 rounded-full hover:bg-sky-600"
                    onClick={apply}
                >
                    Filtern
                </button>
            </div>

            <div className="flex flex-wrap -mx-4 px-4 mt-8 sm:px-8">
                {Array.isArray(data) && data.length > 0 ? (
                    data.map((service) => (
                        <div
                            key={service._id}
                            className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 px-4 mb-4"
                        >
                            <GigCard item={service} />
                        </div>
                    ))
                ) : (
                    !isLoading && !error && (
                        <span className="text-center text-gray-900 text-lg w-full block mt-4">
                            Noch keine Uploads. Seien Sie der Erste.<br/>
                            <a href="/add">
                                <button className="mt-4 bg-sky-500 text-white py-2 px-4 rounded-full hover:bg-sky-600">
                                Hier Angebot veröffentlichen
                                </button>
                            </a>
                        </span>
                    )
                )}
            </div>
        </div>
    );
}

export default Gigs;
