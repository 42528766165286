import React, { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { Modal } from "@mui/material";
import ServiceEditModal from "../../components/serviceEditModal/ServiceEditModal.jsx";
import { EditOutlined } from "@mui/icons-material";
import newRequest from "../../utils/newRequest";
import getCurrentUser from "../../utils/getCurrentUser";
import "./MyGigs.css";

function MyGigs() {
    const [isAddingLoading, setIsAddingLoading] = useState(false);
    const [selectedService, setSelectedService] = useState(null);
    const [open, setOpen] = useState(false);
    const [isConfirmOpen, setIsConfirmOpen] = useState(false);
    const [serviceToDelete, setServiceToDelete] = useState(null);
    const [successMessage, setSuccessMessage] = useState('');
    const currentUser = getCurrentUser();
    const queryClient = useQueryClient();

    const { isLoading, error, data } = useQuery({
        queryKey: ["myServices"],
        queryFn: () => newRequest.get(`/services?userId=${currentUser._id}`).then((res) => res.data),
    });

    const mutation = useMutation({
        mutationFn: (id) => newRequest.delete(`/services/${id}`),
        onSuccess: () => {
            queryClient.invalidateQueries(["myServices"]);
        },
    });

    const handleDelete = (id) => {
        mutation.mutate(id);
    };

    const handleEdit = (service) => {
        setSelectedService(service);
        setOpen(true);
    };

    const handleClose = () => setOpen(false);

    const handleUpdate = async (updatedData) => {
        try {
            await newRequest.put(`/services/${selectedService._id}`, updatedData);
            queryClient.invalidateQueries(["myServices"]);
            setOpen(false);
        } catch (error) {
            console.error('Etwas ist schiefgelaufen, bitte versuchen Sie es erneut');
        }
    };

    const handleConfirmOpen = (service) => {
        setServiceToDelete(service);
        setIsConfirmOpen(true);
    };

    const handleConfirmDelete = async () => {
        try {
            await newRequest.delete(`/services/${serviceToDelete._id}`);
            setSuccessMessage('Service erfolgreich gelöscht!');
            setIsConfirmOpen(false);
            queryClient.invalidateQueries(['myServices']);
            setTimeout(() => {
                setSuccessMessage('');
            }, 3000); // Erfolgsmeldung nach 3 Sekunden ausblenden
        } catch (error) {
            console.error('Fehler beim Löschen des Services:', error);
        }
    };

    const handleConfirmClose = () => {
        setIsConfirmOpen(false);
        setServiceToDelete(null);
    };

    const truncateTitle = (title, maxLength) => {
        if (title.length > maxLength) {
            return title.substring(0, maxLength) + "...";
        }
        return title;
    };

    return (
        <div className="container mx-auto px-4 sm:px-8 lg:px-12 max-w-screen-xl py-4">
            <h1 className="text-xl sm:text-2xl md:text-3xl font-bold text-center">Meine Freelance Jobs</h1>
            <p className="text-base sm:text-lg md:text-xl text-gray-600 mb-4 text-center">
                Managen Sie Ihre Freelance Jobs hier
            </p>

            <div className="flex justify-center mb-4">
                {currentUser.isSeller && (
                    <a href="/add" className="relative sm:static">
                        <button
                            className="bg-blue-600 text-white px-6 py-2 rounded-full shadow-md hover:bg-blue-700 transition ease-in-out duration-300 sm:px-4 sm:py-2"
                            disabled={isAddingLoading}
                            onClick={() => setIsAddingLoading(true)}
                        >
                            {isAddingLoading ? (
                                <svg className="animate-spin h-5 w-5 mr-2 text-white" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                            ) : (
                                <span className="sm:inline">+ Angebot hinzufügen</span>
                            )}
                        </button>
                    </a>
                )}
            </div>
            {isLoading ? (
                <div className="flex justify-center items-center h-64">
                    <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-blue-600"></div>
                </div>
            ) : error ? (
                <p className="text-red-500 text-center">Bitte melden Sie sich an, um Ihre Angebote zu sehen</p>
            ) : (
                <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                    {data.map((service) => (
                        <div key={service._id} className="bg-white shadow-md rounded-lg p-4 flex items-center space-x-4">
                            <img className="w-16 h-16 object-cover rounded-full sm:w-20 sm:h-20 lg:w-24 lg:h-24" src={service.cover} alt="service_image" />
                            <div className="flex-1">
                                <Link to={`/service/${service._id}`} className="text-lg font-semibold text-gray-900">
                                    {truncateTitle(service.title, 15)}
                                    <p className="text-blue-500 font-semibold">
                                        {service.priceHour
                                            ? `${service.priceHour}€ / Stunde`
                                            : `${service.price} €`}
                                    </p>
                                    <p className="text-gray-600">{service.sales} Sales</p>
                                </Link>
                            </div>
                            <div className="flex items-center space-x-4">
                                <button
                                    onClick={() => handleEdit(service)}
                                    className="text-blue-500 hover:text-blue-700"
                                >
                                    <EditOutlined />
                                </button>
                                <button
                                    onClick={() => handleConfirmOpen(service)}
                                    className="text-red-500 hover:text-red-700"
                                >
                                    <DeleteForeverOutlinedIcon />
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            )}

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="flex items-center justify-center"
            >
                <ServiceEditModal service={selectedService} onClose={handleClose} onUpdate={handleUpdate} />
            </Modal>

            <Modal
                open={isConfirmOpen}
                onClose={handleConfirmClose}
                aria-labelledby="confirm-modal-title"
                aria-describedby="confirm-modal-description"
                className="flex items-center justify-center"
            >
                <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm mx-auto">
                    <h2 className="text-lg font-semibold mb-4">Sind Sie sicher das Sie Ihren Service löschen möchten?</h2>
                    <p className="text-gray-700 mb-6">Wenn Sie Ihren Service löschen, wird Ihr Service dauerhaft gelöscht und Sie können den Service nicht mehr verkaufen.</p>
                    <div className="flex justify-end space-x-4">
                        <button
                            onClick={handleConfirmDelete}
                            className="bg-red-600 text-white px-4 py-2 rounded-md hover:bg-red-700"
                        >
                            Ja
                        </button>
                        <button
                            onClick={handleConfirmClose}
                            className="bg-gray-300 text-gray-800 px-4 py-2 rounded-md hover:bg-gray-400"
                        >
                            Nein
                        </button>
                    </div>
                </div>
            </Modal>

            {successMessage && (
                <div className="fixed top-4 right-4 bg-green-600 text-white px-4 py-2 rounded-md shadow-lg">
                    {successMessage}
                </div>
            )}
        </div>
    );
}

export default MyGigs;
