import React, { useState, useEffect } from 'react';
import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js';
import newRequest from "../../utils/newRequest.js";

const PriceSection = () => {
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isBasicPlanActive, setIsBasicPlanActive] = useState(false);
    const clientId = "AVMEyrr9annLMdnhKe0CYlfUGxAFnjUG1WuPChpEHXN0BZYg0-D2ccEtLUwJgPOqowuLEeYnr0M1FZ8M";

    // Fetch the current subscription status on component mount
    useEffect(() => {
        const fetchSubscriptionStatus = async () => {
            try {
                const response = await newRequest.get('/subscription/status');
                if (response.status === 200) {
                    setIsBasicPlanActive(response.data.isBasicPlanActive);
                }
            } catch (error) {
                console.error('Error fetching subscription status:', error.response ? error.response.data : error.message);
            }
        };

        fetchSubscriptionStatus();
    }, []);

    const handleActivateBasicPlan = async () => {
        if (isBasicPlanActive) {
            setErrorMessage('Basic Plan ist bereits aktiviert.');
            setTimeout(() => {
                setErrorMessage('');
            }, 3000); // Fehlermeldung nach 3 Sekunden ausblenden
            return;
        }

        try {
            const response = await newRequest.post('/subscription/activate-basic-plan');

            if (response.status === 200) {
                console.log('Basic Plan aktiviert:', response.data);
                setSuccessMessage('Basic Plan erfolgreich aktiviert!');
                setIsBasicPlanActive(true); // Update the status
                setTimeout(() => {
                    setSuccessMessage('');
                }, 3000); // Erfolgsmeldung nach 3 Sekunden ausblenden
            } else {
                console.error('Fehler bei der Aktivierung des Basic Plans:', response.data.message);
                setErrorMessage('Fehler bei der Aktivierung des Basic Plans.');
                setTimeout(() => {
                    setErrorMessage('');
                }, 3000); // Fehlermeldung nach 3 Sekunden ausblenden
            }
        } catch (error) {
            console.error('Error activating Basic Plan:', error.response ? error.response.data : error.message);
            setErrorMessage('Fehler bei der Aktivierung des Basic Plans.');
            setTimeout(() => {
                setErrorMessage('');
            }, 3000); // Fehlermeldung nach 3 Sekunden ausblenden
        }
    };

    return (
        <section className="py-12 bg-gray-50">
            <div className="max-w-6xl mx-auto px-4">
                <h2 className="text-4xl font-bold text-center mb-8 text-gray-800">Preisplan für Freelancers</h2>
                <p className="text-center text-lg mb-12 text-gray-600">
                    Schließen Sie hier Ihr Abonnement ab, um mit dem Freelancing zu beginnen
                </p>
                <div className="flex flex-wrap -mx-4 justify-center">

                    {/* Basic Plan Card */}
                    <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-8">
                        <div className="bg-white shadow-lg rounded-2xl overflow-hidden border border-gray-200">
                            <div className="p-6">
                                <h3 className="text-3xl font-bold mb-2 text-gray-800">Basic Plan</h3>
                                <p className="text-2xl font-semibold mb-4 text-gray-900">Kostenlos</p>
                                <ul className="mb-6 space-y-2">
                                    <li className="flex items-center text-gray-700">
                                        <svg className="w-5 h-5 text-green-500 mr-2" fill="none" stroke="currentColor"
                                             viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                  d="M5 13l4 4L19 7"></path>
                                        </svg>
                                        1 Service pro Monat hochladen
                                    </li>
                                    <li className="flex items-center text-gray-700">
                                        <svg className="w-5 h-5 text-green-500 mr-2" fill="none" stroke="currentColor"
                                             viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                  d="M5 13l4 4L19 7"></path>
                                        </svg>
                                        25 Nachrichten pro Tag
                                    </li>
                                </ul>
                                <button
                                    onClick={handleActivateBasicPlan}
                                    className="bg-sky-500 text-white font-bold py-2 px-4 rounded-full hover:bg-blue-700"
                                >
                                    Basic Plan aktivieren
                                </button>
                            </div>
                        </div>
                    </div>

                    {/* Paid Plan Card */}
                    <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-8">
                        <div className="bg-white shadow-lg rounded-2xl overflow-hidden border border-gray-200">
                            <div className="p-6">
                                <h3 className="text-3xl font-bold mb-2 text-gray-800">Freelancer</h3>
                                <p className="text-2xl font-semibold mb-4 text-gray-900">14,99 € / Monat</p>
                                <ul className="mb-6 space-y-2">
                                    <li className="flex items-center text-gray-700">
                                        <svg className="w-5 h-5 text-green-500 mr-2" fill="none" stroke="currentColor"
                                             viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                  d="M5 13l4 4L19 7"></path>
                                        </svg>
                                        unbegrenzte Service hochladen
                                    </li>
                                    <li className="flex items-center text-gray-700">
                                        <svg className="w-5 h-5 text-green-500 mr-2" fill="none" stroke="currentColor"
                                             viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                  d="M5 13l4 4L19 7"></path>
                                        </svg>
                                        Status bearbeiten
                                    </li>
                                    <li className="flex items-center text-gray-700">
                                        <svg className="w-5 h-5 text-green-500 mr-2" fill="none" stroke="currentColor"
                                             viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                  d="M5 13l4 4L19 7"></path>
                                        </svg>
                                        Unbegrenzte Nachrichten schreiben
                                    </li>
                                    <li className="flex items-center text-gray-700">
                                        <svg className="w-5 h-5 text-green-500 mr-2" fill="none" stroke="currentColor"
                                             viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                  d="M5 13l4 4L19 7"></path>
                                        </svg>
                                        Premium Support
                                    </li>
                                </ul>
                                <PayPalScriptProvider
                                    options={{
                                        "client-id": clientId,
                                        "components": "buttons",
                                        "enable-funding": "paypal",
                                        "disable-funding": "card,sepa,credit"
                                    }}
                                >
                                    <PayPalButtons
                                        createOrder={(data, actions) => {
                                            return actions.order.create({
                                                purchase_units: [{
                                                    amount: {
                                                        value: '14.99'
                                                    },
                                                }],
                                            });
                                        }}
                                        onApprove={async (data, actions) => {
                                            const details = await actions.order.capture();
                                            console.log('Payment successful:', details);
                                            window.location.href = "https://www.planetbots.de/success";
                                        }}
                                        style={{
                                            color: "blue",
                                            shape: "pill",
                                            layout: "vertical",
                                        }}
                                    />
                                </PayPalScriptProvider>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Success Message */}
            {successMessage && (
                <div className="fixed top-4 right-4 bg-green-600 text-white px-4 py-2 rounded-md shadow-lg">
                    {successMessage}
                </div>
            )}

            {/* Error Message */}
            {errorMessage && (
                <div className="fixed top-4 right-4 bg-red-600 text-white px-4 py-2 rounded-md shadow-lg">
                    {errorMessage}
                </div>
            )}
        </section>
    );
};

export default PriceSection;
