import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import newRequest from '../../utils/newRequest';
import Review from '../review/Review';

const Reviews = ({ gigId }) => {
    const [isReviewed, setIsReviewed] = useState(false);
    const [showReviewError, setShowReviewError] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const queryClient = useQueryClient();

    const { isLoading, error, data } = useQuery({
        queryKey: ['reviews', gigId],
        queryFn: () => newRequest.get(`/reviews/${gigId}`).then((res) => res.data),
    });

    const mutation = useMutation({
        mutationFn: (review) => newRequest.post('/reviews', review),
        onSuccess: () => {
            setIsReviewed(true);
            setShowSuccessMessage(true);
            queryClient.invalidateQueries(['reviews', gigId]);
            setTimeout(() => setShowSuccessMessage(false), 3000);
        },
        onError: (error) => {
            setShowReviewError(true);
            setErrorMessage(error.response?.data?.message || 'Sie können nicht Ihr eigenen Service bewerten');
        },
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        if (isReviewed) {
            alert("Sie haben das Angebot schon bewertet");
            return;
        }
        const desc = e.target[0].value;
        const star = e.target[1].value;
        mutation.mutate({ gigId, desc, star });
    };

    useEffect(() => {
        if (showReviewError) {
            const timeoutId = setTimeout(() => {
                setShowReviewError(false);
                setErrorMessage('');
            }, 3000);

            return () => clearTimeout(timeoutId);
        }
    }, [showReviewError]);

    return (
        <div className="max-w-4xl mx-auto py-8 px-4">
            {isLoading ? (
                <div className="flex justify-center items-center h-16">
                    <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-blue-600"></div>
                </div>
            ) : error ? (
                <p className="text-red-500">Etwas ist schiefgelaufen, bitte versuchen Sie es erneut</p>
            ) : (
                <>
                    {data.length === 0 ? (
                        <p className="text-gray-500">Noch keine Bewertungen</p>
                    ) : (
                        data.map((review) => <Review key={review._id} review={review} />)
                    )}
                </>
            )}
            <div className="mt-12 bg-white p-6 rounded-lg shadow-md">
                <h4 className="text-xl font-semibold mb-4">Schreiben Sie eine Bewertung</h4>
                <form onSubmit={handleSubmit} className="space-y-4">
                    <textarea
                        className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-500"
                        placeholder="Bewertung schreiben..."
                        rows="4"
                    />
                    <div className="flex items-center space-x-4">
                        <select
                            className="p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                            name="rating"
                            id="rating"
                        >
                            <option value={1}>1</option>
                            <option value={2}>2</option>
                            <option value={3}>3</option>
                            <option value={4}>4</option>
                            <option value={5}>5</option>
                        </select>
                        <button
                            type="submit"
                            className={`px-4 py-2 rounded-lg text-white ${
                                isReviewed
                                    ? 'bg-gray-400 cursor-not-allowed'
                                    : 'bg-sky-500 hover:bg-blue-700'
                            }`}
                            disabled={isReviewed}
                        >
                            Senden
                        </button>
                    </div>
                    {showReviewError && (
                        <p className="text-red-500 mt-2">{errorMessage}</p>
                    )}
                    {showSuccessMessage && (
                        <div className="flex justify-center items-center mt-2 text-green-500">
                            <svg
                                className="h-6 w-6"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M5 13l4 4L19 7"
                                />
                            </svg>
                            <p className="ml-2">Bewertung erfolgreich abgegeben!</p>
                        </div>
                    )}
                </form>
            </div>
        </div>
    );
};

export default Reviews;