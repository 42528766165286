import React from 'react';
import { Link } from 'react-router-dom';

const posts = [
    {
        id: 1,
        title: 'Wie Sie mit Ihrer Freelance Karriere richtig durchstarten',
        description:
            'In der heutigen digitalen Ära hat das Freelancing eine neue Dimension erreicht...',
        date: '19.08.2024',
        datetime: '19-08-2024',
        category: { title: 'Marketing' },
        author: {
            name: 'Finn Paustian',
        },
    },
    {
        id: 2,
        title: 'Wie Sie mehr Freelance Jobs bekommen',
        description:
            "Für Freelancer kann es eine Herausforderung sein, neue Kunden und Projekte zu finden...",
        date: '19.08.2024',
        datetime: '19-08-2024',
        category: { title: 'Marketing' },
        author: {
            name: 'Finn Paustian',
        },
    },
    {
        id: 3,
        title: 'Den richtigen Freelancer für Ihren Auftrag zu finden...',
        description:
            "Da online unzählige Fachleute zur Verfügung stehen...",
        date: '19.08.2024',
        datetime: '19-08-2024',
        category: { title: 'Marketing' },
        author: {
            name: 'Finn Paustian',
        },
    },
    {
        id: 4,
        title: 'Die Zukunft des Freelancing: Ein wachsender Markt mit enormen Potenzial',
        description:
            "Der Markt für Freelancer wächst rasant und wird in den kommenden Jahren...",
        date: '14.09.2024',
        datetime: '14-09-2024',
        category: { title: 'News' },
        author: {
            name: 'Finn Paustian',
        },
    },
];

const AllBlogs = () => {
    return (
        <div className="bg-white py-24 sm:py-32">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto max-w-2xl lg:mx-0">
                    <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Alle Blogeinträge</h2>
                    <p className="mt-2 text-lg leading-8 text-gray-600">
                        Hier finden Sie alle unsere Blogeinträge
                    </p>
                </div>
                <div className="mx-auto mt-10 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 border-t border-gray-200 pt-10 sm:mt-16 sm:pt-16 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                    {posts.map((post) => (
                        <article key={post.id} className="flex max-w-xl flex-col items-start justify-between">
                            <div className="flex items-center gap-x-4 text-xs">
                                <time dateTime={post.datetime} className="text-gray-500">
                                    {post.date}
                                </time>
                                <span className="relative z-10 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600">
                                    {post.category.title}
                                </span>
                            </div>
                            <div className="group relative">
                                <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                                    <Link to={`/post/${post.id}`}>
                                        <span className="absolute inset-0" />
                                        {post.title}
                                    </Link>
                                </h3>
                                <p className="mt-5 line-clamp-3 text-sm leading-6 text-gray-600">{post.description}</p>
                            </div>
                            <div className="relative mt-8 flex items-center gap-x-4">
                                <div className="text-sm leading-6">
                                    <p className="font-semibold text-gray-900">
                                        by {post.author.name}
                                    </p>
                                </div>
                            </div>
                        </article>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default AllBlogs;
