import React, {useState, useEffect} from 'react';
import Modal from 'react-modal';
import VerticalCarousel from "../../components/slide/Slide.jsx";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useQuery} from "@tanstack/react-query";
import newRequest from "../../utils/newRequest.js";
import Reviews from "../../components/reviews/Reviews.jsx";

// Stelle sicher, dass Modal an das root-Element gebunden ist
Modal.setAppElement('#root');

function Gig() {
    const {id} = useParams();
    const [dataUser, setDataUser] = useState(null);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const navigate = useNavigate();

    const {isLoading, error, data} = useQuery({
        queryKey: ['service', id],
        queryFn: () => newRequest.get(`/services/single/${id}`).then((res) => res.data),
    });

    useEffect(() => {
        if (data) {
            const fetchData = async () => {
                try {
                    const res = await newRequest.get(`/users/${data.userId}`);
                    setDataUser(res.data);
                } catch (error) {
                    alert("Benutzerinformationen konnten nicht geladen werden");
                }
            };

            fetchData();
        }
    }, [data]);

    const handleContactFromGig = async () => {
        if (!currentUser || !data || data.userId === undefined || currentUser.id === data.userId) {
            alert("Sie können sich nicht selbst anschreiben");
            return;
        }

        const conversationId = `${currentUser.id}${data.userId}`;

        try {
            const res = await newRequest.get(`/conversations/single/${conversationId}`);
            navigate(`/message/${res.data?.id}`);
        } catch (error) {
            if (error.response.status === 404) {
                try {
                    const response = await newRequest.post(`/conversations`, {to: data.userId});
                    navigate(`/message/${response.data?.id}`);
                } catch (error) {
                    alert('Etwas ist schiefgelaufen, bitte versuchen Sie es erneut');
                }
            } else {
                alert('Etwas ist schiefgelaufen, bitte versuchen Sie es erneut');
            }
        }
    };

    const handleOrder = async () => {
        if (!currentUser || !data || currentUser.id === data.userId) {
            alert("Sie können nicht Ihr eigenes Angebot bestellen");
            return;
        }

        try {
            await newRequest.post(`/orders/${data._id}`, {gigId: data._id, quantity: 1});
            navigate('/cart');
        } catch (err) {
            alert('Etwas ist schiefgelaufen, bitte versuchen Sie es erneut');
        }
    };

    const openModal = (image) => {
        setSelectedImage(image);
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setSelectedImage(null);
    };

    return (
        <div className="bg-gray-50 py-8 px-4 sm:px-8">
            <div className="container mx-auto max-w-6xl">
                {isLoading ? (
                    <div className="flex justify-center items-center h-64">
                        <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-blue-600"></div>
                    </div>
                ) : error ? (
                    <p className="text-red-500 text-center">Etwas ist schiefgelaufen, bitte versuchen Sie es erneut</p>
                ) : (
                    <>
                        <div className="flex flex-col md:flex-row md:justify-between">
                            <div className="md:w-2/3 mb-8 md:mb-0">
                                <h1 className="text-3xl font-bold text-gray-900 break-words mb-4">{data?.title}</h1>
                                <Link to={`/profile/${dataUser?.username}`}
                                      className="text-blue-600 hover:text-blue-800 font-semibold text-lg">
                                    {dataUser?.username}
                                </Link>
                            </div>
                            <div className="md:w-1/3 flex flex-col items-start">
                                <button
                                    className="bg-sky-500 text-white px-4 py-2 rounded-full shadow hover:bg-blue-700 mb-4 w-full md:w-auto"
                                    onClick={handleContactFromGig}
                                >
                                    Verkäufer kontaktieren
                                </button>
                                <button
                                    className="bg-sky-500 text-white px-4 py-2 rounded-full shadow hover:bg-blue-700 w-full md:w-auto"
                                    onClick={handleOrder}
                                >
                                    Angebot bestellen
                                </button>
                            </div>
                        </div>

                        <div className="flex flex-col lg:flex-row lg:space-x-8 mt-8">
                            <div className="lg:w-2/3">
                                <div className="mb-8">
                                    <h2 className="text-xl font-semibold mb-4">Bilder</h2>
                                    {data?.images && (
                                        <VerticalCarousel slidesToShow={1} arrowsScroll={1} className="rounded-lg">
                                            {data.images.map((img) => (
                                                <img
                                                    key={img}
                                                    src={img}
                                                    alt="Service"
                                                    className="object-contain w-full h-80 rounded-lg image-thumbnail cursor-pointer"
                                                    onClick={() => openModal(img)}
                                                />
                                            ))}
                                        </VerticalCarousel>
                                    )}
                                </div>
                                <div className="mb-8">
                                    <h2 className="text-xl font-bold mb-4">Beschreibung</h2>
                                    <p className="text-gray-800 font-bold leading-relaxed text-justify break-words max-w-full lg:max-w-2xl">
                                        {data?.desc}
                                    </p>
                                </div>
                            </div>

                            <div className="lg:w-1/3 space-y-8">
                                <div>
                                    <h2 className="text-xl font-bold mb-2">Preis</h2>
                                    <p className="text-gray-800 font-bold text-lg">{data?.price} €</p>
                                </div>
                                <div>
                                    <h2 className="text-xl font-bold mb-2">Kurze Beschreibung</h2>
                                    <p className="text-gray-800 font-bold break-words">{data?.shortDesc}</p>
                                </div>
                                <div>
                                    <h2 className="text-xl font-bold mb-2">Liefertage</h2>
                                    <p className="text-gray-800 font-bold">{data?.deliveryTime} Tage</p>
                                </div>
                                <div>
                                    <h2 className="text-xl font-bold mb-2">Überarbeitungen</h2>
                                    <p className="text-gray-800 font-bold">{data?.revisionNumber}</p>
                                </div>
                                <div>
                                    <h2 className="text-xl font-bold mb-2">Dienstleistungsmerkmale</h2>
                                    <ul className="list-disc list-inside font-bold text-gray-800">
                                        {data?.features?.map((feature) => (
                                            <li key={feature}>{feature}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="mt-16">
                            <Reviews gigId={id}/>
                        </div>
                    </>
                )}
            </div>

            {/* Modal für die Originalgröße des Bildes */}
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                className="fixed inset-0 flex items-center justify-center p-4 bg-black bg-opacity-90"
                overlayClassName="fixed inset-0 bg-black bg-opacity-90 z-50"
            >
                <div className="relative w-full h-full flex items-center justify-center">
                    <button
                        onClick={closeModal}
                        className="absolute top-4 right-4 text-white text-xl bg-red-600 hover:bg-red-700 rounded-full w-10 h-10 flex items-center justify-center z-50 shadow-lg transition-transform transform hover:scale-110 focus:outline-none focus:ring-2 focus:ring-red-500"
                    >
                        X
                    </button>
                    <img
                        src={selectedImage}
                        alt="Full Size"
                        className="object-contain max-w-full max-h-full"
                    />
                </div>
            </Modal>
        </div>
    );
}

export default Gig;
