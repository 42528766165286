export const serviceReducer = (state, action) => {
    switch (action.type) {
        case "CHANGE_INPUT":
            return {
                ...state,
                [action.payload.name]: action.payload.value
            };
        case "ADD_FEATURE":
            return {
                ...state,
                features: [...state.features, action.payload]
            };
        case "REMOVE_FEATURE":
            return {
                ...state,
                features: state.features.filter(f => f !== action.payload)
            };
        case "ADD_IMAGES":
            return {
                ...state,
                cover: action.payload.cover,
                images: action.payload.images
            };
        default:
            return state;
    }
};

export const INITIAL_STATE = {
    username: '',
    title: '',
    cat: 'chatbots',
    desc: '',
    price: '',
    priceHour: '',
    shortTitle: '',
    shortDesc: '',
    deliveryTime: '',
    revisionNumber: '',
    features: [],
    cover: '',
    images: []
};