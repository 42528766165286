import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import newRequest from "../../utils/newRequest";

const Message = () => {
    const { id } = useParams();
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    const [error, setError] = useState("");

    const queryClient = useQueryClient();

    const conversationExists = id !== undefined && id !== null;

    const { isLoading, data } = useQuery({
        queryKey: ["messages", id],
        queryFn: () =>
            newRequest.get(`/messages/${id}`).then((res) => res.data),
        enabled: conversationExists,
    });

    const mutation = useMutation({
        mutationFn: (message) => {
            return newRequest.post(`/messages`, message);
        },
        onSuccess: () => {
            queryClient.invalidateQueries(["messages", id]);
            setError(""); // Leere den Fehler, wenn die Nachricht erfolgreich gesendet wurde
        },
        onError: (error) => {
            if (error.response.status === 403) {
                setError("Sie haben das tägliche Nachrichtenlimit erreicht.");
            } else {
                console.error("Nachricht konnte nicht gesendet werden:", error);
            }
        }
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        mutation.mutate({
            conversationId: id,
            desc: e.target[0].value,
        });
        e.target[0].value = "";
    };

    const sortedMessages = data?.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

    return (
        <div className="container mx-auto py-4 px-4 h-screen flex flex-col">
            <div className="flex flex-col flex-1 overflow-y-auto bg-gray-100 rounded-lg p-4 shadow-inner">
                <div className="flex justify-between items-center px-4 sm:px-8">
                    <span className="text-gray-700 text-xl font-bold">
                        planetbots - Nachrichtenverlauf
                    </span>
                </div>
                <div className="mt-4 px-4 sm:px-8 flex-1">
                    {isLoading ? (
                        <div className="flex justify-center items-center h-full">
                            <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-sky-500"></div>
                        </div>
                    ) : (
                        <div className="space-y-4">
                            {sortedMessages && sortedMessages.length > 0 ? (
                                sortedMessages.map((m) => (
                                    <div
                                        className={`flex ${m.userId === currentUser._id ? 'justify-end' : 'justify-start'}`}
                                        key={m._id}
                                    >
                                        <div
                                            className={`max-w-[75%] p-4 mb-4 rounded-lg shadow-md ${
                                                m.userId === currentUser._id
                                                    ? 'bg-blue-500 text-white rounded-br-none'
                                                    : 'bg-white text-gray-900 rounded-bl-none'
                                            }`}
                                        >
                                            <span className="block text-sm font-semibold mb-1">
                                                {m.userId === currentUser._id ? (
                                                    currentUser.username
                                                ) : (
                                                    <Link
                                                        to={`/profile/${m.senderUsername}`}
                                                        className="text-blue-500 hover:underline"
                                                    >
                                                        {m.senderUsername}
                                                    </Link>
                                                )}
                                            </span>
                                            <p className="text-sm">
                                                {m.desc}
                                            </p>
                                            <span className="block text-xs text-right mt-2 text-gray-400">
                                                {new Date(m.createdAt).toLocaleTimeString()}
                                            </span>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <p className="text-gray-500 text-center">Noch keine Nachrichten</p>
                            )}
                        </div>
                    )}
                </div>
            </div>
            {conversationExists && (
                <div className="bottom-0 left-0 right-0 p-4 bg-white border-t border-gray-200 shadow-lg flex justify-center">
                    <form onSubmit={handleSubmit} className="flex w-full max-w-4xl">
                        <input
                            type="text"
                            placeholder="Nachricht schreiben..."
                            className="border border-gray-300 rounded-full px-4 py-2 focus:outline-none w-full text-sm h-10"
                        />
                        <button
                            type="submit"
                            className="max-h-16 bg-sky-500 text-white px-4 py-2 ml-2 focus:outline-none h-10 w-24 flex items-center justify-center rounded-full"
                            disabled={mutation.isLoading}
                        >
                            {mutation.isLoading ? (
                                <svg className="animate-spin h-5 w-5 text-white" viewBox="0 0 24 24">
                                    <circle
                                        className="opacity-25"
                                        cx="12"
                                        cy="12"
                                        r="10"
                                        stroke="currentColor"
                                        strokeWidth="4"
                                    ></circle>
                                    <path
                                        className="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                    ></path>
                                </svg>
                            ) : (
                                "Senden"
                            )}
                        </button>
                    </form>
                    {error && (
                        <div className="mt-2 text-red-500 text-center">
                            {error}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default Message;