import React, { useState } from "react";
import newRequest from "../../utils/newRequest";
import Link from "@mui/material/Link";
import Alert from "../../components/alert/Alert";

const ForgotPassword = () => {
    const [email, setEmail] = useState("");
    const [emailSend, setEmailSend] = useState(false);
    const [message, setMessage] = useState("");

    const handleForgotPassword = async () => {
        try {
            const response = await newRequest.post("/auth/forgot-password", { email });
            setMessage(response.data.message);
            setEmailSend(true);
        } catch (error) {
            setMessage("Diese E-Mail Adresse existiert nicht");
        }
    };


    return (
        <div className="max-w-sm mx-auto">
            <div className="flex lg:flex-1">
                <a href="#" className="-m-1.5 p-1.5">
                    <Link className="navbar-link" href="/" underline="none">
                        <span className="navbar-text">planetbots</span>
                    </Link>
                </a>
            </div>
            <h1 className="text-2xl font-bold mb-4">Passwort zurücksetzen</h1>
            <div className="mb-4">
                <input type="text" id="email" placeholder="Ihre E-Mail Adresse" value={email} onChange={(e) => setEmail(e.target.value)}
                       className="w-full block mb-1 border border-gray-300 rounded px-3 py-2"/>
            </div>
            <button className="bg-sky-500 text-white px-4 py-2 rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 mb-4"
                    onClick={handleForgotPassword}>
                Passwort zurücksetzen
            </button>
            {emailSend && <Alert type="success" message={message} />}
            {message && <Alert type="error" message={message} />}
        </div>
    );
};

export default ForgotPassword;
