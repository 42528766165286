import React from 'react';

const Alert = ({ type, message }) => {
    const alertClasses = {
        success: 'bg-green-100 border-green-400 text-green-700',
        error: 'bg-red-100 border-red-400 text-red-700',
        warning: 'bg-yellow-100 border-yellow-400 text-yellow-700',
        info: 'bg-blue-100 border-blue-400 text-blue-700',
    };

    const alertBorderClasses = {
        success: 'border-green-400',
        error: 'border-red-400',
        warning: 'border-yellow-400',
        info: 'border-blue-400',
    };

    return (
        <div
            className={`border-l-4 p-4 ${alertClasses[type]} border-l-4 ${alertBorderClasses[type]} mb-4`}
            role="alert"
        >
            <p className="font-bold">{type.toUpperCase()}</p>
            <p>{message}</p>
        </div>
    );
};

export default Alert;
