import React, { useState } from 'react';
import './Footer.css'
import newRequest from "../../utils/newRequest";
import '../../index.css'

const Footer = () => {
    const [email, setEmail] = useState('');
    const [isValidEmail, setIsValidEmail] = useState(true);
    const [message, setMessage] = useState('');
    const [isSuccess, setIsSuccess] = useState(false);

    const handleSubscribe = async () => {
        if (!validateEmail(email)) {
            setIsValidEmail(false);
            return;
        }
        try {
            await newRequest.post('/newsletter/subscribedNewsletter', { email });
            setIsSuccess(true); // Erfolg auf true setzen
            setMessage('Danke für das Abonnieren unseres Newsletters!');
            setEmail(''); // E-Mail-Feld zurücksetzen

            setTimeout(() => {
                setMessage('');
                setIsSuccess(false);
            }, 5000);
        } catch (err) {
            setIsSuccess(false); // Erfolg auf false setzen
            setMessage('Es gab ein Problem beim Abonnieren. Bitte versuche es später erneut.');

            setTimeout(() => {
                setMessage('');
            }, 5000);
        }
    }

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }

    return (
        <div className="container mx-auto py-8">
            <div className="grid grid-cols-1 sm:grid-cols-5 gap-4 text-center sm:text-left">
                {/* Support */}
                <div className="p-2">
                    <h3 className="text-lg font-semibold mb-2">Support</h3>
                    <ul className="text-sm">
                        <li>support@planetbots.de</li>
                        <li>
                            <a href="https://discord.gg/HHG7rqs8Un"
                               target="_blank"
                               rel="noopener noreferrer"
                               className="hover:text-gray-700">Discord</a>
                        </li>
                    </ul>
                </div>

                {/* Company */}
                <div className="p-2">
                    <h3 className="text-lg font-semibold mb-2" id="about">Unternehemn</h3>
                    <ul className="text-sm hover:text-gray-700">
                        <li><a href="/about">Über uns</a></li>
                    </ul>
                </div>

                {/* Legal */}
                <div className="p-2">
                    <h3 className="text-lg font-semibold mb-2">Rechtliches</h3>
                    <ul className="text-sm">
                        <li><a href="/privacyPolicy" className="hover:text-gray-700">Datenschutzerklärung</a></li>
                        <li><a href="/termsofservice" className="hover:text-gray-700">Nutzungsbedingungen</a></li>
                        <li><a href="/propertyClaims" className="hover:text-gray-700">Impressum</a></li>
                    </ul>
                </div>

                {/* Newsletter */}
                <div className="p-2" id="newsletter">
                    <h3 className="text-xl font-bold mb-4 text-gray-800">Abonniere unseren Newsletter</h3>
                    <p className="text-gray-600 text-sm mb-4">
                        Bleiben Sie auf dem Laufenden mit den neuesten Updates, Aktionen und Angeboten.
                    </p>
                    <div className="flex flex-col sm:flex-row items-center">
                        <input
                            type="email"
                            value={email}
                            placeholder="Your E-Mail"
                            className={`w-full p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400 text-gray-700 transition duration-200 ease-in-out ${
                                !isValidEmail ? 'border-red-500' : 'border-gray-300'
                            }`}
                            onChange={(e) => {
                                setEmail(e.target.value);
                                setIsValidEmail(true);
                                setMessage('');
                            }}
                        />
                        <button
                            className="mt-4 sm:mt-0 sm:ml-4 w-full sm:w-auto bg-blue-500 text-white font-semibold px-4 py-2 rounded-lg hover:bg-blue-600 transition duration-200 ease-in-out"
                            onClick={handleSubscribe}
                        >
                            Subscribe
                        </button>
                    </div>
                    {!isValidEmail && (
                        <p className="text-red-500 text-sm mt-2">
                            Bitte gib eine gültige E-Mail-Adresse ein.
                        </p>
                    )}
                    {message && (
                        <p className={`text-sm mt-4 ${isSuccess ? 'text-green-500' : 'text-red-500'}`}>
                            {message}
                        </p>
                    )}
                </div>
            </div>

            {/* Footer */}
            <div className="mt-6 border-t border-gray-300 pt-6 flex flex-col items-center">
                <div className="text-sm text-gray-600">
                    © 2024 planetbots. All rights reserved.
                </div>
            </div>
        </div>
    );
};

export default Footer;
