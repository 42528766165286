import React from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Divider, Heading, Image, Stack, Text } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import newRequest from "../../utils/newRequest";

const GigCard = ({ item }) => {
    const { isLoading, error, data: reviews } = useQuery({
        queryKey: ['reviews', item._id],
        queryFn: () => newRequest.get(`/reviews/${item._id}`).then(res => res.data),
    });

    // Berechne den Durchschnittswert der Bewertungen
    const averageRating = reviews && reviews.length > 0
        ? reviews.reduce((acc, review) => acc + review.star, 0) / reviews.length
        : 0;

    // Anzahl der Sterne für die durchschnittliche Bewertung
    const stars = Array(Math.round(averageRating)).fill(null);

    // Hilfsfunktion, um Text nach einer bestimmten Länge zu kürzen
    const truncateText = (text, maxLength) => {
        if (text.length > maxLength) {
            return text.substring(0, maxLength) + "...";
        }
        return text;
    };

    return (
        <Link to={`/service/${item._id}`} className="block p-4 border border-gray-200 rounded-lg shadow-md bg-white">
            <Card maxWidth='275' minHeight="425" maxHeight="425" className="gig-card">
                <CardBody marginBottom='20px' className="flex flex-col justify-between h-full">
                    <Image
                        src={item.cover}
                        alt='gig cover'
                        borderRadius='lg'
                        height='225'
                    />
                    <Stack mt='5' spacing='1'>
                        <Heading size='md' className='text-gray-950 font-bold'>
                            {truncateText(item.title, 50)} {/* Titel auf 20 Zeichen beschränken */}
                        </Heading>
                        <Text className='text-gray-800 max-h-20 overflow-hidden text-ellipsis'>
                            {truncateText(item.desc, 75)} {/* Beschreibung auf 50 Zeichen beschränken */}
                        </Text>
                        <Divider my='4'/>
                        <div>
                            {isLoading ? (
                                <div className="flex justify-center items-center h-16">
                                    <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-blue-600"></div>
                                </div>
                            ) : error ? (
                                <p className="text-red-500">Bewertungen konnten nicht geladen werden</p>
                            ) : reviews.length === 0 ? (
                                <p className="text-gray-600">Noch keine Bewertungen</p>
                            ) : (
                                <>
                                    <div className="flex items-center space-x-1 mt-2 max-h-20">
                                        {stars.map((_, i) => (
                                            <img
                                                src="/images/star.png"  // Pfad zum Sternenbild
                                                alt="star"
                                                className="w-5 h-5"
                                                key={i}
                                            />
                                        ))}
                                        <span className="text-gray-600 ml-2">{reviews.length} Bewertungen</span>
                                    </div>
                                </>
                            )}
                        </div>
                        <Divider my='4'/>
                        <Text className='text-gray-950 text-xl font-bold'>
                            {item.price} €
                        </Text>
                    </Stack>
                </CardBody>
            </Card>
        </Link>
    );
};

export default GigCard;
