import React, { useState, useEffect } from 'react';
import newRequest from "../../utils/newRequest";
import { useNavigate } from "react-router-dom";

const VerificationPage = () => {
    const [verificationCode, setVerificationCode] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const navigate = useNavigate();

    // Lädt die E-Mail aus dem localStorage, wenn die Komponente gerendert wird
    useEffect(() => {
        const storedEmail = localStorage.getItem('emailForVerification');
        if (storedEmail) {
            setEmail(storedEmail);
        } else {
            setMessage('Keine E-Mail für die Verifizierung gefunden. Bitte kehren Sie zum vorherigen Schritt zurück.');
        }
    }, []);

    const handleVerification = async () => {
        try {
            const response = await newRequest.post('/auth/verify-email', { email, verificationCode });
            setMessage(response.data.message);
            navigate('/login');
        } catch (err) {
            setMessage(err.response.data.error || 'Etwas ist schief gelaufen. Bitte versuchen Sie es erneut.');
        }
    };

    return (
        <div className="min-h-screen flex flex-col justify-center items-center bg-gray-100">
            <div className="bg-white shadow-lg rounded-lg p-8 max-w-sm w-full">
                <h2 className="text-2xl font-semibold text-gray-800 text-center mb-6">
                    E-Mail verifizieren
                </h2>
                {email ? (
                    <div className="mb-4">
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                            E-Mail-Adresse
                        </label>
                        <input
                            id="email"
                            type="email"
                            value={email}
                            disabled
                            className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md bg-gray-100 cursor-not-allowed sm:text-sm"
                        />
                    </div>
                ) : (
                    <p className="text-red-600 mb-4">{message}</p>
                )}
                <div className="mb-6">
                    <label htmlFor="verificationCode" className="block text-sm font-medium text-gray-700">
                        Verifizierungscode
                    </label>
                    <input
                        id="verificationCode"
                        type="text"
                        placeholder="Verifizierungscode eingeben..."
                        value={verificationCode}
                        onChange={(e) => setVerificationCode(e.target.value)}
                        className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                </div>
                <button
                    onClick={handleVerification}
                    className="w-full bg-green-500 text-white font-semibold py-2 px-4 rounded-md hover:bg-sky-500 transition-colors duration-300"
                    disabled={!email}
                >
                    E-Mail verifizieren
                </button>
                {message && (
                    <p className={`mt-4 text-sm ${message.includes('Erfolg') ? 'text-green-600' : 'text-red-600'}`}>
                        {message}
                    </p>
                )}
            </div>
        </div>
    );
};

export default VerificationPage;
