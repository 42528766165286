import React, { useState, useEffect } from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

const Slide = ({ children, limit = 10 }) => {
    const [slidesToShow, setSlidesToShow] = useState(1);

    useEffect(() => {
        const updateSlidesToShow = () => {
            const width = window.innerWidth;
            if (width < 480) {
                setSlidesToShow(1.5); // Für mobile Geräte
            } else if (width < 768) {
                setSlidesToShow(2); // Für Tablets
            } else if (width < 1024) {
                setSlidesToShow(3); // Für kleinere Bildschirme
            } else {
                setSlidesToShow(5); // Für größere Bildschirme
            }
        };

        updateSlidesToShow();
        window.addEventListener("resize", updateSlidesToShow);

        return () => window.removeEventListener("resize", updateSlidesToShow);
    }, []);

    const slides = React.Children.toArray(children).slice(0, limit);

    const PrevArrow = ({ onClick }) => (
        <button
            onClick={onClick}
            className="absolute left-[-10px] top-1/3 transform -translate-y-1/2 bg-sky-500 p-4 text-white rounded-full text-3xl z-10"
        >
            &#10094;
        </button>
    );

    const NextArrow = ({ onClick }) => (
        <button
            onClick={onClick}
            className="absolute right-[-10px] top-1/3 transform -translate-y-1/2 bg-sky-500 p-4 text-white rounded-full text-3xl z-10"
        >
            &#10095;
        </button>
    );

    return (
        <div className={`${slidesToShow > 0 ? "block" : "hidden"} relative -top-28`}>
            <div className="container mx-auto px-4 relative">
                {slidesToShow > 0 && (
                    <AliceCarousel
                        items={slides}
                        infinite
                        responsive={{
                            0: { items: 1.5 },
                            480: { items: 2 },
                            768: { items: 3 },
                            1024: { items: 5 }
                        }}
                        controlsStrategy="alternate"
                        renderPrevButton={() => <PrevArrow />}
                        renderNextButton={() => <NextArrow />}
                        autoPlay={true}
                        autoPlayInterval={3000}
                    />
                )}
            </div>
        </div>
    );
};

export default Slide;
