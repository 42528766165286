import React, {useEffect, useReducer, useState} from "react";
import { serviceReducer, INITIAL_STATE } from "../../reducers/serviceReducer";
import upload from "../../utils/upload";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import newRequest from "../../utils/newRequest";

const Add = () => {
    const [singleFile, setSingleFile] = useState(undefined);
    const [files, setFiles] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [addingFeature, setAddingFeature] = useState(false);
    const [publish, setPublish] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [validationError, setValidationError] = useState('');
    const [subscriptionError, setSubscriptionError] = useState('');

    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const [state, dispatch] = useReducer(serviceReducer, INITIAL_STATE);

    useEffect(() => {
        if (currentUser?.username) {
            dispatch({
                type: "CHANGE_INPUT",
                payload: { name: "username", value: currentUser.username }
            });
        }
    }, [currentUser?.username]);

    const mutation = useMutation({
        mutationFn: async (serviceData) => {
            return await newRequest.post('/services', serviceData);
        },
        onSuccess: () => {
            queryClient.invalidateQueries(['services']);
        },
        onError: (error) => {
            setErrorMessage('');
        }
    });

    const handleChange = (e) => {
        dispatch({
            type: "CHANGE_INPUT",
            payload: { name: e.target.name, value: e.target.value }
        });
    };

    const handleFeature = (e) => {
        e.preventDefault();
        setAddingFeature(true);
        const feature = e.target[0].value;
        if (feature) {
            dispatch({
                type: "ADD_FEATURE",
                payload: feature,
            });
            e.target[0].value = "";
        }
        setAddingFeature(false);
    };

    const handleRemoveFeature = (featureToRemove) => {
        dispatch({
            type: "REMOVE_FEATURE",
            payload: featureToRemove,
        });
    };

    const handleUpload = async () => {
        if (!singleFile) {
            setValidationError("Sie müssen erst Fotos hochladen");
            return;
        }

        setUploading(true);
        setUploadSuccess(false);
        try {
            const cover = await upload(singleFile);
            const images = await Promise.all([...files].map(file => upload(file)));
            dispatch({ type: "ADD_IMAGES", payload: { cover, images } });
            setUploadSuccess(true);
            setValidationError("");
        } catch (err) {
            alert('Das Hochladen ist fehlgeschlagen, bitte versuchen Sie es erneut');
        } finally {
            setUploading(false);
        }
    };

    const validateForm = () => {
        if (!state.username ||
            !state.title ||
            !state.cover ||
            !state.cat ||
            !state.desc ||
            (!state.price && !state.priceHour) || // Hier wurde die Bedingung angepasst
            !state.shortTitle ||
            !state.shortDesc ||
            !state.deliveryTime ||
            !state.revisionNumber ||
            !state.features
        ) {
            setValidationError("Bitte füllen Sie alle Felder aus");
            return false;
        }
        if (!state.price && !state.priceHour) {
            throw new Error('Bitte geben Sie entweder einen Preis oder einen Preis pro Stunde an.');
        }
        if (!state.cover) {
            setValidationError("Sie müssen jeweils ein Foto hochladen");
            return false;
        }
        if (!Number.isInteger(Number(state.deliveryTime)) || Number(state.deliveryTime) <= 0) {
            setValidationError("Liefertage müssen eine positive ganze Zahl sein");
            return false;
        }
        if (!Number.isInteger(Number(state.revisionNumber)) || Number(state.revisionNumber) < 0) {
            setValidationError("Anzahl der Überarbeitungen muss eine nicht-negative ganze Zahl sein");
            return false;
        }
        setValidationError("");
        return true;
    };


    const checkSubscriptionStatus = async () => {
        try {
            const response = await newRequest.get("/subscription/verify-subscription");
            if (!response.data.active) {
                setSubscriptionError(
                    <div>
                        Sie brauchen ein aktives Abonnement{' '}
                        <button
                            onClick={() => navigate('/price')}
                            className="text-sky-500 hover:underline"
                        >
                            Hier abonnieren
                        </button>
                    </div>
                );
                return false;
            }

            if (response.data.plan === "basic") {
                const servicesResponse = await newRequest.get("/api/services/count");
                if (servicesResponse.data.count >= 1) {
                    setSubscriptionError("Mit einem Basic-Plan können Sie nur einen Service pro Monat hinzufügen.");
                    return false;
                }
            }

            setSubscriptionError("");
            return true;
        } catch (err) {
            setSubscriptionError("Fehler beim Überprüfen des Abonnementstatus. Bitte schließen sie erst unter Preise ein Abonnement ab oder kontaktieren Sie uns!");
            return false;
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const currentUser = JSON.parse(localStorage.getItem('currentUser'));

        if (!currentUser) {
            setErrorMessage("Sie müssen sich erst anmelden, um Ihr Jobangebot zu veröffentlichen.");
        }

        if (!await checkSubscriptionStatus()) {
            return;
        }
        if (!validateForm()) {
            return;
        }

        const finalState = {
            username: state.username,
            title: state.title,
            cat: state.cat,
            desc: state.desc,
            price: state.price,
            priceHour: state.priceHour,
            shortTitle: state.shortTitle,
            shortDesc: state.shortDesc,
            deliveryTime: Number(state.deliveryTime),
            revisionNumber: Number(state.revisionNumber),
            features: state.features,
            images: state.images,
            cover: state.cover,
        };

        try {
            setPublish(true);
            await mutation.mutateAsync(finalState);
            setPublish(false);
            navigate("/myservices");
        } catch (error) {
            setPublish(false);
            setSubscriptionError(
                <div>
                    Mit einem Basic Plan können Sie nur einen Service pro Monat veröffentlichen. Upgraden Sie Ihr Abonnement.{' '}
                    <button
                        onClick={() => navigate('/price')}
                        className="text-sky-500 hover:underline"
                    >
                        Hier upgraden
                    </button>
                </div>
            );
        }
    };


    return (
        <div className="bg-gray-50 py-8 px-4 -mt-14 sm:px-8">
            <div className="container mx-auto max-w-4xl">
                <div className="flex flex-wrap">
                    <div className="w-full md:w-1/2 pr-4 mb-4 md:mb-0">
                        <h2 className="text-2xl font-semibold text-gray-900 mb-6">Neuen Freelance Job hinzufügen</h2>
                        <div className="mb-6">
                            <label className="block text-gray-700 font-medium mb-2">Benutzername</label>
                            <p className="w-full border border-gray-300 rounded-lg px-3 py-2 bg-gray-100 text-gray-500">{currentUser?.username}</p>
                        </div>
                        <div className="mb-6">
                            <label htmlFor="title" className="block text-gray-700 font-medium mb-2">Titel *</label>
                            <input
                                type="text"
                                name="title"
                                placeholder="Titel Ihres Freelance Jobs"
                                onChange={handleChange}
                                className="w-full border border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-sky-500"
                            />
                        </div>
                        <div className="mb-6">
                            <label htmlFor="cat" className="block text-gray-700 font-medium mb-2">Kategorie *</label>
                            <select
                                name="cat"
                                id="cat"
                                onChange={handleChange}
                                className="w-full border border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-sky-500"
                            >
                                <option value="chatbots">Chatbots</option>
                                <option value="webdesign">Website Design</option>
                                <option value="webdev">Website Programmierung</option>
                                <option value="shopifycustom">Shopify Anpassungen</option>
                                <option value="seo">SEO - Search Engine Optimization</option>
                                <option value="smm">Social Media Marketing</option>
                                <option value="emm">E-Mail Marketing</option>
                                <option value="branding">Brand</option>
                                <option value="translation">Übersetzung</option>
                                <option value="blogs">Blog</option>
                                <option value="youtube">Video Bearbeitung</option>
                                <option value="thumbnail">Thumbnail kreieren</option>
                                <option value="logodesign">Logo Design</option>
                                <option value="shorts">Short Videos</option>
                                <option value="tiktok">TikTok Videos</option>
                                <option value="ml">Machine Learning</option>
                                <option value="dl">Deep Learning</option>
                                <option value="neuronetze">Neuronale Netze</option>
                                <option value="wordpress">Wordpress</option>
                                <option value="landingpage">Landingpage</option>
                                <option value="frontend">Frontend Entwicklung</option>
                                <option value="backend">Backend Entwicklung</option>
                                <option value="fullstack">Fullstack Entwicklung</option>
                                <option value="bugfixing">Bug Fixing</option>
                                <option value="speedoptimization">Speed Verbesserung</option>
                                <option value="iosapp">iOS App Entwicklung</option>
                                <option value="androidapp">Android App Entwicklung</option>
                                <option value="crossplattform">Crossplattform App Entwicklung</option>
                                <option value="pwa">PWA Entwicklung</option>
                                <option value="mysql">MySQL Datenbank</option>
                                <option value="mongodb">MongoDB Datenbank</option>
                                <option value="qareviews">Q&A Reviews</option>
                                <option value="usertests">User Tests</option>
                            </select>
                        </div>
                        <div className="mb-6">
                            <label htmlFor="mainCover"
                                   className="block text-gray-700 font-medium mb-2">Hauptbild *</label>
                            <input
                                className="w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:border-0 file:text-sm file:font-semibold file:bg-sky-500 file:text-white hover:file:bg-sky-600 rounded-lg cursor-pointer focus:outline-none focus:ring-2 focus:ring-sky-500"
                                type="file"
                                onChange={(e) => setSingleFile(e.target.files[0])}
                            />
                            <label htmlFor="servicePictures"
                                   className="block text-gray-700 font-medium mb-2 mt-4">Angebotsbilder *</label>
                            <input
                                className="w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:border-0 file:text-sm file:font-semibold file:bg-sky-500 file:text-white hover:file:bg-sky-600 rounded-lg cursor-pointer focus:outline-none focus:ring-2 focus:ring-sky-500"
                                type="file"
                                multiple
                                onChange={(e) => setFiles(e.target.files)}
                            />
                            <button
                                className={`mt-4 bg-sky-500 text-white px-4 py-2 rounded-lg hover:bg-sky-600 focus:outline-none focus:ring-2 focus:ring-sky-500 ${
                                    uploading ? "opacity-50 cursor-not-allowed" : ""
                                }`}
                                onClick={handleUpload}
                                disabled={uploading}
                            >
                                {uploading ? (
                                    <div className="flex items-center">
                                        <svg className="animate-spin h-5 w-5 mr-2 text-white" viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                                    strokeWidth="4"></circle>
                                            <path className="opacity-75" fill="currentColor"
                                                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                        </svg>
                                        Hochladen...
                                    </div>
                                ) : (
                                    "Hochladen"
                                )}
                            </button>
                            {uploadSuccess && (
                                <p className="mt-2 text-green-500 text-sm">Erfolgreich hochgeladen</p>
                            )}
                        </div>
                        <div className="mb-6">
                            <label htmlFor="desc" className="block text-gray-700 font-medium mb-2">Beschreibung
                                *</label>
                            <textarea
                                name="desc"
                                id="desc"
                                placeholder="Beschreiben Sie Ihren Freelance Job ausführlich"
                                cols="30"
                                rows="5"
                                onChange={handleChange}
                                className="w-full border border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-sky-500"
                            ></textarea>
                        </div>
                        <div className="mb-6">
                            <p className="text-gray-700 font-medium mb-2">
                                Preis: {state.price ? `$${state.price}` : state.priceHour ? `$${state.priceHour} pro Stunde` : "Nicht angegeben"}
                            </p>
                            <label htmlFor="price" className="block text-gray-700 font-medium mb-2">Preis (€) *</label>
                            <input
                                type="number"
                                name="price"
                                placeholder="Preis"
                                onChange={handleChange}
                                className="w-full border border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-sky-500"
                            />
                        </div>
                        <div className="mb-6">
                            <label htmlFor="priceHour" className="block text-gray-700 font-medium mb-2">Preis (€) /
                                Stunde *</label>
                            <input
                                type="number"
                                name="priceHour"
                                placeholder="Preis pro Stunde"
                                onChange={handleChange}
                                className="w-full border border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-sky-500"
                            />
                        </div>
                    </div>
                    <div className="w-full md:w-1/2 pr-4 px-4 mt-14 md:mb-0">
                        <div className="mb-6">
                            <label htmlFor="shortTitle" className="block text-gray-700 font-medium mb-2">Kurzer Titel
                                *</label>
                            <input
                                type="text"
                                name="shortTitle"
                                placeholder="Kurzer informativer Titel"
                                onChange={handleChange}
                                className="w-full border border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-sky-500"
                            />
                        </div>
                        <div className="mb-6">
                            <label htmlFor="shortDesc" className="block text-gray-700 font-medium mb-2">Kurze Beschreibung *</label>
                            <textarea
                                name="shortDesc"
                                id="shortDesc"
                                placeholder="Kurze Beschreibung"
                                cols="30"
                                rows="2"
                                onChange={handleChange}
                                className="w-full border border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-sky-500"
                            ></textarea>
                        </div>
                        <div className="mb-6">
                            <label htmlFor="deliveryTime" className="block text-gray-700 font-medium mb-2">Liefertage (in Tagen) *</label>
                            <input
                                type="number"
                                name="deliveryTime"
                                placeholder="Wann können Sie liefern"
                                onChange={handleChange}
                                className="w-full border border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-sky-500"
                                step="1"
                            />
                        </div>
                        <div className="mb-6">
                            <label htmlFor="revisionNumber" className="block text-gray-700 font-medium mb-2">Anzahl der Überarbeitungen *</label>
                            <input
                                type="number"
                                name="revisionNumber"
                                placeholder="Anzahl der Überarbeitungen"
                                onChange={handleChange}
                                className="w-full border border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-sky-500"
                                step="1"
                            />
                        </div>
                        <div className="mb-6 border border-gray-300 rounded-lg px-4 py-4">
                            <h4 className="text-lg font-medium mb-4">Features *</h4>
                            <form action="" className="mb-4" onSubmit={handleFeature}>
                                <input
                                    type="text"
                                    placeholder="Features"
                                    className="w-full mb-2 border border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-sky-500"
                                />
                                <button
                                    type="submit"
                                    className="bg-sky-500 text-white px-4 py-2 rounded-lg hover:bg-sky-600 focus:outline-none focus:ring-2 focus:ring-sky-500"
                                    disabled={addingFeature}
                                >
                                    {addingFeature ? "Hinzufügen..." : "Hinzufügen"}
                                </button>
                            </form>
                            <ul className="list-disc pl-5">
                                {state?.features?.map((f) => (
                                    <li key={f} className="text-gray-700 flex justify-between">
                                        {f}
                                        <button
                                            type="button"
                                            onClick={() => handleRemoveFeature(f)}
                                            className="ml-4 text-red-500 hover:text-red-700 focus:outline-none"
                                        >
                                            Entfernen
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="flex justify-center mt-6">
                    <button
                        className={`bg-sky-500 text-white px-6 py-3 rounded-lg hover:bg-sky-600 focus:outline-none focus:ring-2 focus:ring-sky-500 ${publish ? "opacity-50 cursor-not-allowed" : ""}`}
                        onClick={handleSubmit}
                        disabled={publish}
                    >
                        {publish ? (
                            <div className="flex items-center">
                                <svg className="animate-spin h-5 w-5 mr-2 text-white" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                            strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor"
                                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                                Veröffentlichen...
                            </div>
                        ) : (
                            "Veröffentlichen"
                        )}
                    </button>
                </div>
                {validationError && (
                    <p className="mt-4 text-red-500">{validationError}</p>
                )}
                {subscriptionError && (
                    <p className="mt-4 text-red-500">{subscriptionError}</p>
                )}
                {errorMessage && (
                    <p className="mt-4 text-red-500">{errorMessage}</p>
                )}
            </div>
        </div>
    );
};

export default Add;
