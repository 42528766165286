import React, { useEffect, useState } from 'react';
import newRequest from '../../utils/newRequest';

const MySubscriptions = () => {
    const [subscription, setSubscription] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchSubscription = async () => {
            try {
                const response = await newRequest.get('/subscription/getCurrentSubscription');
                setSubscription(response.data);
            } catch (err) {
                setError('Etwas ist schiefgelaufen, bitte versuchen Sie es erneut');
            } finally {
                setLoading(false);
            }
        };

        fetchSubscription();
    }, []);

    const handleCancelSubscription = async () => {
        try {
            const response = await newRequest.post('/subscription/cancel-subscription', { subscriptionId: subscription.subscriptionId });
            setSubscription(prev => ({ ...prev, subscriptionStatus: 'INACTIVE', nextBillingDate: null }));
            alert('Sie haben Ihr Abonnement erfolgreich gekündigt, schade das Sie uns verlassen');
        } catch (err) {
            alert('Etwas ist bei der Kündigung schiefgelaufen, bitte versuchen Sie es erneut');
        }
    };

    if (loading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500"></div>
            </div>
        );
    }

    if (error) {
        return <div className="text-center text-red-500 mt-4">{error}</div>;
    }

    return (
        <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-lg">
            <h1 className="text-3xl font-bold text-gray-800 mb-6">Mein Abonnement</h1>
            <div className="bg-gray-50 p-6 rounded-lg shadow-inner">
                <h2 className="text-2xl font-semibold text-gray-700 mb-4">Mein derzeitiges Abonnement</h2>
                <div className="space-y-4">
                    <div className="flex justify-between items-center bg-white p-4 rounded-lg shadow-sm">
                        <span className="text-gray-600 font-medium">Status:</span>
                        <span className={`text-lg font-semibold ${subscription.subscriptionStatus === 'ACTIVE' ? 'text-green-600' : 'text-red-600'}`}>
                            {subscription.subscriptionStatus}
                        </span>
                    </div>
                    <div className="flex justify-between items-center bg-white p-4 rounded-lg shadow-sm">
                        <span className="text-gray-600 font-medium">Plan:</span>
                        <span className="text-lg font-semibold text-gray-800">{subscription.plan}</span>
                    </div>
                    <div className="flex justify-between items-center bg-white p-4 rounded-lg shadow-sm">
                        <span className="text-gray-600 font-medium">Start:</span>
                        <span className="text-lg font-semibold text-gray-800">{subscription.startDate ? new Date(subscription.startDate).toLocaleDateString() : 'Nicht verfügbar'}</span>
                    </div>
                    <div className="flex justify-between items-center bg-white p-4 rounded-lg shadow-sm">
                        <span className="text-gray-600 font-medium">Nächste Abrechnung:</span>
                        <span className="text-lg font-semibold text-gray-800">{subscription.nextBillingDate ? new Date(subscription.nextBillingDate).toLocaleDateString() : 'Nicht verfügbar'}</span>
                    </div>
                    <div className="flex justify-between items-center bg-white p-4 rounded-lg shadow-sm">
                        <span className="text-gray-600 font-medium">Abonnement kündigen</span>
                        <button
                            onClick={handleCancelSubscription}
                            className="bg-sky-500 text-white px-3 py-3 rounded-lg hover:bg-sky-600 focus:outline-none focus:ring-2 focus:ring-sky-500"
                        >
                            Abonnement kündigen
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MySubscriptions;
