import React from 'react';
import {FaCheck} from "react-icons/fa";

const LearnFromTheBestCta = () => {
    return (
        <div className="py-8 sm:py-36 -mt-16 rounded-3xl">
            <div className="mx-auto px-4 lg:px-12">
                <div className="mx-auto max-w-5xl lg:max-w-full lg:flex lg:items-center lg:justify-between">
                    <div className="lg:w-1/2 lg:text-left">
                        <h1 className="text-4xl font-bold text-center lg:text-left leading-10 text-sky-500">
                            Abonniere unseren Newsletter
                        </h1>
                        <p className="text-md mt-12 font-semibold tracking-tight text-gray-900 sm:text-xl flex items-center">
                            <FaCheck className="mr-2 text-2xl"/>
                            Bleib immer auf dem neuesten Stand – erhalte aktuelle News und Updates direkt in dein
                            Postfach.
                        </p>
                        <p className="text-md mt-2 font-semibold tracking-tight text-gray-900 sm:text-xl flex items-center">
                            <FaCheck className="mr-2 text-2xl"/>
                            Erfahre als Erster von neuen Funktionen, spannenden Möglichkeiten und exklusiven Angeboten.
                        </p>
                        <p className="text-md mt-2 font-semibold tracking-tight text-gray-900 sm:text-xl flex items-center">
                            <FaCheck className="mr-2 text-xl"/>
                            Sei informiert über unsere aktuellen Projekte und zukünftigen Entwicklungen.
                        </p>
                    </div>
                </div>

                <div className="mx-auto max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
                    <div className="mt-8 sm:mt-10 flex items-center justify-center">
                        <a
                            href="/#newsletter"
                            className="rounded-full bg-indigo-500 px-6 py-3 sm:px-8 sm:py-4 text-base sm:text-lg font-semibold text-gray-950 shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
                        >
                            Abonniere jetzt unseren Newsletter
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LearnFromTheBestCta;