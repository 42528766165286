import React, { useEffect, useState } from 'react';
import './Profile.css';
import { useParams, useNavigate } from 'react-router-dom';
import newRequest from '../../utils/newRequest';
import ProfileSettingsModal from '../../components/profilesettings/ProfileSettingsModal';
import { Modal } from '@mui/material';
import { MdBuild, MdContacts } from "react-icons/md";
import {useQuery} from "@tanstack/react-query";
import Review from "../../components/review/Review.jsx";

const Profile = () => {
    const { username } = useParams();
    const [data, setData] = useState(null);
    const [open, setOpen] = useState(false);
    const [success, setSuccess] = useState('')

    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    const navigate = useNavigate();

    const handleClose = () => setOpen(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const profileResponse = await newRequest.get(`/profile/${username}`);
                setData(profileResponse.data);
            } catch (error) {
                alert('Etwas ist schiefgelaufen, versuchen Sie es bitte erneut');
            }
        };

        fetchData();
    }, [username]);

    const { data: reviews, isLoading: reviewsLoading, error: reviewsError } = useQuery(
        ['userReviews', data?.id],
        () => newRequest.get(`/reviews/user/${data.id}`).then(res => res.data),
        {
            enabled: !!data?.id // Abfrage nur starten, wenn die Benutzer-ID vorhanden ist
        }
    );

    const handleOpen = () => {
        if (currentUser && data && data.username === currentUser.username) {
            setOpen(true);
        } else {
            alert('Sie können nur ihr eigenes Profil bearbeiten');
        }
    };

    const handleContactProfile = async () => {
        if (!currentUser || !data || data.id === undefined || currentUser.id === data.id) {
            alert("Sie können nicht sich selbst anschreiben");
            return;
        }

        const conversationId = currentUser.id + data.id;

        try {
            const res = await newRequest.get(`/conversations/single/${conversationId}`);
            navigate(`/message/${res.data?.id}`);
        } catch (err) {
            if (err.response && err.response.status === 404) {
                try {
                    const response = await newRequest.post(`/conversations`, {
                        to: data.id,
                    });
                    navigate(`/message/${response.data?.id}`);
                } catch (error) {
                    alert("Etwas ist schiefgelaufen, bitte versuchen Sie es erneut");
                }
            } else {
                alert("Etwas ist schiefgelaufen, bitte versuchen Sie es erneut");
            }
        }
    };

    const handleUpdate = async (newData) => {
        try {
            await newRequest.post(`/profile/${username}/settings`, newData);
            const response = await newRequest.get(`/profile/${username}`);
            const updatedUser = response.data;
            setData(updatedUser);
            localStorage.setItem('currentUser', JSON.stringify(updatedUser));
            setSuccess('Ihr Profil wurde erfolgreich aktualisiert');
        } catch (error) {
            console.log('Etwas ist schiefgelaufen, bitte versuchen Sie es erneut');
        }
    };

    if (!data) {
        return <div className="text-center py-8">Lädt...</div>;
    }

    return (
        <div className="max-w-4xl mx-auto py-8 px-4">
            <header className="text-center mb-8">
                <h1 className="text-3xl font-bold text-gray-800 mb-2">{data.username}</h1>
                <p className="text-gray-600">{data.country}</p>
                <div className="flex justify-center gap-4 mt-4">
                    <button onClick={handleContactProfile} className="btn-primary flex items-center">
                        <MdContacts className="mr-2"/> Kontaktieren
                    </button>
                    {currentUser && data.username === currentUser.username && (
                        <button onClick={handleOpen} className="btn-secondary flex items-center">
                            <MdBuild className="mr-2"/> Einstellungen
                        </button>
                    )}
                </div>
            </header>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-8">
                <div className="bg-white p-6 rounded-lg shadow-md">
                    <h2 className="text-xl font-semibold mb-4">Über mich</h2>
                    <p className="text-gray-700 mb-2"><strong>Vorname:</strong> {data.firstname}</p>
                    <p className="text-gray-700 mb-2"><strong>Nachname:</strong> {data.lastname}</p>
                    <p className="text-gray-700"><strong>Land:</strong> {data.country}</p>
                </div>

                <div className="bg-white p-6 rounded-lg shadow-md">
                    <h2 className="text-xl font-semibold mb-4">Meine Erfahrungen</h2>
                    <p className="text-gray-700">{data.desc}</p>
                </div>
            </div>

            <div className="bg-white p-6 rounded-lg shadow-md mb-8">
                <h2 className="text-xl font-semibold mb-4">Mein Social Media</h2>
                <div className="flex items-center gap-4">
                    {data.instagram && (
                        <a href={data.instagram} target="_blank" rel="noopener noreferrer" className="social-icon">
                            <img src="/images/instagram.png" alt="Instagram" className="max-w-12 max-h-12" />
                        </a>
                    )}
                    {data.tiktok && (
                        <a href={data.tiktok} target="_blank" rel="noopener noreferrer" className="social-icon">
                            <img src="/images/tiktok.png" alt="TikTok" className="max-w-12 max-h-12" />
                        </a>
                    )}
                    {data.linkedin && (
                        <a href={data.linkedin} target="_blank" rel="noopener noreferrer" className="social-icon">
                            <img src="/images/linkedin.png" alt="LinkedIn" className="max-w-12 max-h-12" />
                        </a>
                    )}
                </div>
            </div>

            {/* Bewertungen anzeigen */}
            <div className="bg-white p-6 rounded-lg shadow-md">
                <h2 className="text-xl font-semibold mb-4">Meine Bewertungen</h2>
                {reviewsLoading ? (
                    <div>Lädt Bewertungen...</div>
                ) : reviewsError ? (
                    <p className="text-red-500">Etwas ist schiefgelaufen, Bewertungen konnten nicht geladen werden.</p>
                ) : reviews?.length === 0 ? (
                    <p className="text-gray-500">Noch keine Bewertungen</p>
                ) : (
                    reviews.map(review => <Review key={review._id} review={review} />)
                )}
            </div>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="flex items-center justify-center"
            >
                <ProfileSettingsModal onClose={handleClose} onUpdate={handleUpdate}/>
            </Modal>
        </div>
    );
};

export default Profile;
