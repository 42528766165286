// components/ProfileSettingsModal.jsx

import React, { useState } from 'react';
import newRequest from "../../utils/newRequest";
import { useParams } from "react-router-dom";

const ProfileSettingsModal = ({ onClose, onUpdate }) => {
    const { username } = useParams();
    const [formData, setFormData] = useState({
        firstname: '',
        lastname: '',
        country: '',
        desc: '',
        instagram: '',
        tiktok: '',
        linkedin: ''
    });
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleSave = async () => {
        try {
            await newRequest.post(`/profile/${username}/settings`, formData);
            setSuccess('Ihr Profil wurde erfolgreich aktualisiert');
            onUpdate(formData); // Notify parent component about the update
            setTimeout(() => {
                onClose(); // Close the modal after a short delay to show the success message
            }, 1000);
        } catch (error) {
            setError('Etwas ist schiefgelaufen, versuchen Sie es bitte erneut');
        }
    };

    return (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white rounded-lg shadow-lg p-8 max-w-md w-full">
                <h3 className="text-2xl font-semibold mb-4 text-gray-800">Profil Einstellungen</h3>
                {error && <p className="text-red-500 mb-4">{error}</p>}
                {success && <p className="text-green-500 mb-4">{success}</p>}

                <div className="space-y-4">
                    {[
                        { name: 'firstname', placeholder: 'Vorname' },
                        { name: 'lastname', placeholder: 'Nachname' },
                        { name: 'country', placeholder: 'Land' },
                        { name: 'desc', placeholder: 'Erfahrungen' },
                        { name: 'instagram', placeholder: 'Instagram' },
                        { name: 'tiktok', placeholder: 'TikTok' },
                        { name: 'linkedin', placeholder: 'LinkedIn' },
                    ].map((field) => (
                        <input
                            key={field.name}
                            type="text"
                            name={field.name}
                            placeholder={field.placeholder}
                            value={formData[field.name]}
                            onChange={handleInputChange}
                            className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    ))}
                </div>

                <div className="flex justify-end space-x-4 mt-6">
                    <button
                        onClick={handleSave}
                        className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    >
                        Speichern
                    </button>
                    <button
                        onClick={onClose}
                        className="bg-gray-300 text-gray-800 px-4 py-2 rounded-md hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500"
                    >
                        Abbrechen
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ProfileSettingsModal;
