import React, {useState} from 'react';

const ServiceEditModal = ({ service, onClose, onUpdate }) => {
    const [formData, setFormData] = useState({
        cat: service.cat || '',
        title: service.title || '',
        desc: service.desc || '',
        cover: service.cover || '',
        price: service.price || '',
        priceHour: service.priceHour || '',
        shortTitle: service.shortTitle || '',
        shortDesc: service.shortDesc || '',
        deliveryTime: service.deliveryTime || '',
        revisionNumber: service.revisionNumber || '',
        features: service.features || ''
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        await onUpdate(formData);
        onClose();
    }

    return (
        <div className="bg-gray-50 p-8 rounded-lg shadow-lg max-w-full sm:max-w-lg md:max-w-2xl lg:max-w-4xl xl:max-w-6xl mx-auto">
            <h2 className="text-2xl font-bold mb-6 text-gray-900">Service bearbeiten</h2>
            <form onSubmit={handleSubmit}>
                <div className="mb-4">
                    <select
                        name="cat"
                        id="cat"
                        onChange={handleChange}
                        className="w-full border border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-sky-500"
                    >
                        <option value="chatbots">Chatbots</option>
                        <option value="webdesign">Website Design</option>
                        <option value="webdev">Website Programmierung</option>
                        <option value="shopifycustom">Shopify Anpassungen</option>
                        <option value="seo">SEO - Search Engine Optimization</option>
                        <option value="smm">Social Media Marketing</option>
                        <option value="emm">E-Mail Marketing</option>
                        <option value="branding">Brand</option>
                        <option value="translation">Übersetzung</option>
                        <option value="blogs">Blog</option>
                        <option value="youtube">Video Bearbeitung</option>
                        <option value="thumbnail">Thumbnail kreieren</option>
                        <option value="logodesign">Logo Design</option>
                        <option value="shorts">Short Videos</option>
                        <option value="tiktok">TikTok Videos</option>
                        <option value="ml">Machine Learning</option>
                        <option value="dl">Deep Learning</option>
                        <option value="neuronetze">Neuronale Netze</option>
                        <option value="wordpress">Wordpress</option>
                        <option value="landingpage">Landingpage</option>
                        <option value="frontend">Frontend Entwicklung</option>
                        <option value="backend">Backend Entwicklung</option>
                        <option value="fullstack">Fullstack Entwicklung</option>
                        <option value="bugfixing">Bug Fixing</option>
                        <option value="speedoptimization">Speed Verbesserung</option>
                        <option value="iosapp">iOS App Entwicklung</option>
                        <option value="androidapp">Android App Entwicklung</option>
                        <option value="crossplattform">Crossplattform App Entwicklung</option>
                        <option value="pwa">PWA Entwicklung</option>
                        <option value="mysql">MySQL Datenbank</option>
                        <option value="mongodb">MongoDB Datenbank</option>
                        <option value="qareviews">Q&A Reviews</option>
                        <option value="usertests">User Tests</option>
                    </select>
                </div>
                <div className="mb-4">
                    <input
                        type="text"
                        id="title"
                        name="title"
                        placeholder="Titel"
                        value={formData.title}
                        onChange={handleChange}
                        className="w-full p-2 border border-gray-300 rounded"
                    />
                </div>
                <div className="mb-4">
                    <textarea
                        id="desc"
                        name="desc"
                        placeholder="Beschreibung"
                        value={formData.desc}
                        onChange={handleChange}
                        className="w-full p-2 border border-gray-300 rounded"
                    />
                </div>
                <div className="mb-4">
                    <input
                        type="number"
                        id="price"
                        name="price"
                        placeholder="Preis in €"
                        value={formData.price}
                        onChange={handleChange}
                        className="w-full p-2 border border-gray-300 rounded"
                    />
                </div>
                <div className="mb-4">
                    <input
                        type="number"
                        id="priceHour"
                        name="priceHour"
                        placeholder="Preis pro Stunde"
                        value={formData.priceHour}
                        onChange={handleChange}
                        className="w-full p-2 border border-gray-300 rounded"
                    />
                </div>
                <div className="mb-4">
                    <input
                        type="text"
                        id="shortTitle"
                        name="shortTitle"
                        placeholder="Kurzer Titel"
                        value={formData.shortTitle}
                        onChange={handleChange}
                        className="w-full p-2 border border-gray-300 rounded"
                    />
                </div>
                <div className="mb-4">
                    <input
                        type="text"
                        id="shortDesc"
                        name="shortDesc"
                        placeholder="Kurze Beschreibung"
                        value={formData.shortDesc}
                        onChange={handleChange}
                        className="w-full p-2 border border-gray-300 rounded"
                    />
                </div>
                <div className="mb-4">
                    <input
                        type="text"
                        id="deliveryTime"
                        name="deliveryTime"
                        placeholder="Liefertage"
                        value={formData.deliveryTime}
                        onChange={handleChange}
                        className="w-full p-2 border border-gray-300 rounded"
                    />
                </div>
                <div className="mb-4">
                    <input
                        type="text"
                        id="revisionNumber"
                        name="revisionNumber"
                        placeholder="Überarbeitungen"
                        value={formData.revisionNumber}
                        onChange={handleChange}
                        className="w-full p-2 border border-gray-300 rounded"
                    />
                </div>
                <div className="mb-4">
                    <input
                        type="text"
                        id="features"
                        name="features"
                        placeholder="Dienstleistungsmerkmale"
                        value={formData.features}
                        onChange={handleChange}
                        className="w-full p-2 border border-gray-300 rounded"
                    />
                </div>
                <button type="submit"
                        className="w-full bg-sky-500 text-white py-3 rounded-lg shadow hover:bg-blue-600 transition duration-300 ease-in-out">Aktualisieren
                </button>
            </form>
            <button onClick={onClose}
                    className="btn-secondary w-full mt-4 bg-gray-200 text-gray-700 py-3 rounded-lg shadow hover:bg-gray-300 transition duration-300 ease-in-out">Abbrechen
            </button>
        </div>
    );
};

export default ServiceEditModal;