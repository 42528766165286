import axios from "axios";

const upload = async (file) => {
    const data = new FormData();
    data.append("file", file);
    data.append("upload_preset", "planetbots");

    try {
        const res = await axios.post("https://api.cloudinary.com/v1_1/dyho9ys47/image/upload", data);
        const { url } = res.data;
        return url;
    } catch (err) {
        console.error("Error uploading file:", err);
        throw err; // Rethrow error for further handling if needed
    }
};

export default upload;
