import React, { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import newRequest from "../../utils/newRequest";
import { Link } from "react-router-dom";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";

const Cart = () => {
    const [isDeleteSuccessful, setIsDeleteSuccessful] = useState(false);
    const queryClient = useQueryClient();

    const { isLoading, error, data } = useQuery({
        queryKey: ["cart"],
        queryFn: () => newRequest.get(`/cards`).then((res) => res.data),
    });

    const deleteItemMutation = useMutation(
        (gigId) => newRequest.delete(`/cards/delete/${gigId}`),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["cart"]);
                setIsDeleteSuccessful(true);
                setTimeout(() => setIsDeleteSuccessful(false), 3000); // Erfolgsnachricht nach 3 Sekunden ausblenden
            },
        }
    );

    const handleDelete = (gigId) => {
        if (window.confirm("Sind Sie sicher, dass Sie das Angebot entfernen möchten?")) {
            deleteItemMutation.mutate(gigId);
        }
    };

    const truncateTitle = (title, maxLength) => {
        if (title.length > maxLength) {
            return title.substring(0, maxLength) + "...";
        }
        return title;
    };

    return (
        <div className="container mx-auto px-4 sm:px-6 lg:px-8 max-w-screen-xl py-4">
            <div className="text-center mb-4">
                <h1 className="text-xl sm:text-2xl md:text-3xl font-bold">Ihr Warenkorb</h1>
                {isDeleteSuccessful && (
                    <div className="flex justify-center items-center mt-2">
                        <svg
                            className="h-6 w-6 text-green-500"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M5 13l4 4L19 7"
                            />
                        </svg>
                        <p className="text-green-500 text-sm ml-2">Das Angebot wurde erfolgreich entfernt</p>
                    </div>
                )}
            </div>
            <p className="text-base sm:text-lg md:text-xl text-gray-600 mb-8 text-center">
                Überprüfen Sie Ihre Bestellungen
            </p>
            {isLoading ? (
                <div className="flex justify-center items-center h-64">
                    <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-blue-600"></div>
                </div>
            ) : error ? (
                <p className="text-red-500 text-center">Etwas ist schiefgelaufen, bitte versuchen Sie es erneut</p>
            ) : data.items.length === 0 ? (
                <p className="text-gray-600 text-center">Sie haben noch keine Bestellung aufgegeben</p>
            ) : (
                <div className="overflow-x-auto">
                    <table className="min-w-full bg-white divide-y divide-gray-200">
                        <thead className="bg-gray-100">
                        <tr>
                            <th className="py-3 px-4 text-left text-sm font-medium text-gray-900">Bild</th>
                            <th className="py-3 px-4 text-left text-sm font-medium text-gray-900">Titel</th>
                            <th className="py-3 px-4 text-left text-sm font-medium text-gray-900">Preis</th>
                            <th className="py-3 px-4 text-left text-sm font-medium text-gray-900">Menge</th>
                            <th className="py-3 px-4 text-left text-sm font-medium text-gray-900">Total</th>
                            <th className="py-3 px-4 text-left text-sm font-medium text-gray-900">Status</th>
                            <th className="py-3 px-4 text-left text-sm font-medium text-gray-900">Aktionen</th>
                        </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                        {data.items.map((item) => (
                            <tr key={item.gigId}>
                                <td className="py-4 px-4">
                                    <img
                                        className="w-16 h-16 object-cover rounded-full"
                                        src={item.img}
                                        alt="cart_item_img"
                                    />
                                </td>
                                <td className="py-4 px-4">
                                    <Link to={`/service/${item.gigId}`} className="text-blue-600 hover:underline">
                                        {truncateTitle(item.title, 15)}
                                    </Link>
                                </td>
                                <td className="py-4 px-4 text-blue-500 font-semibold">{item.price} €</td>
                                <td className="py-4 px-4 text-gray-600">{item.quantity}</td>
                                <td className="py-4 px-4 text-blue-500 font-semibold">{item.price * item.quantity} €</td>
                                <td className="py-4 px-4">
                                        <span
                                            className={`inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium ${
                                                item.status === "completed"
                                                    ? "bg-green-100 text-green-800"
                                                    : "bg-yellow-100 text-yellow-800"
                                            }`}
                                        >
                                            {item.status === "completed" ? "Fertig" : "In Bearbeitung"}
                                        </span>
                                </td>
                                <td className="py-4 px-4">
                                    <button
                                        onClick={() => handleDelete(item.gigId)}
                                        className="text-red-500 hover:text-red-700 ml-2"
                                    >
                                        <DeleteForeverOutlinedIcon />
                                    </button>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};

export default Cart;
