import { useQuery } from '@tanstack/react-query';
import React from 'react';
import newRequest from '../../utils/newRequest';

const Review = ({ review }) => {
    const { isLoading, error, data } = useQuery({
        queryKey: [review.userId],
        queryFn: () => newRequest.get(`/users/${review.userId}`).then((res) => res.data),
    });

    return (
        <div className="p-4 mb-4 border border-gray-200 rounded-lg shadow-sm bg-white">
            {isLoading ? (
                <div className="flex justify-center items-center h-16">
                    <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-blue-600"></div>
                </div>
            ) : error ? (
                <p className="text-red-500">Etwas ist schiefgelaufen, bitte versuchen Sie es erneut</p>
            ) : (
                <div>
                    <div className="flex items-center mb-2">
                        <a href={`/profile/${data.username}`} className="text-blue-600 hover:underline">
                            <span className="font-semibold">{data.username}</span>
                        </a>
                        <span className="ml-2 text-gray-500">{data.country}</span>
                    </div>
                    <div className="flex items-center mb-2">
                        {Array.from({ length: review.star }).map((_, i) => (
                            <img
                                src="/images/star.png"
                                alt="star"
                                className="w-5 h-5"
                                key={i}
                            />
                        ))}
                        <span className="ml-2 text-gray-800 font-medium">{review.star}</span>
                    </div>
                    <p className="text-gray-700">{review.desc}</p>
                </div>
            )}
        </div>
    );
};

export default Review;
