import React, {Suspense, useEffect, useRef, useState} from "react";
import CookieConsent from "react-cookie-consent";
import { useQuery } from "@tanstack/react-query";
import newRequest from "../../utils/newRequest";
import ServicesCategories from "../../components/servicesCategories/ServicesCategories.jsx";
import GigCard from "../../components/gigCard/GigCard.jsx";
import globeAltIcon from "@heroicons/react/16/solid/esm/GlobeAltIcon.js";
import briefcaseIcon from "@heroicons/react/16/solid/esm/BriefcaseIcon.js";
import faceSmileIcon from "@heroicons/react/16/solid/esm/FaceSmileIcon.js";
import fireIcon from "@heroicons/react/16/solid/esm/FireIcon.js";
import LearnFromTheBestCTA from "../../components/learnFromTheBest/LearnFromTheBestCTA.jsx";
import NewsletterCTA from "../../components/newsletterCTA/NewsletterCTA.jsx";
import AllServices from "../allServices/AllServices.jsx";
import {Link} from "react-router-dom"; // Importiere die Gig-Liste

// Lazy load components
const Featured = React.lazy(() => import("../../components/featured/Featured"));
const WhyPlanetbots = React.lazy(() => import("../../components/whyplanetbots/WhyPlanetbots"));
const Blog = React.lazy(() => import("../../components/blog/Blog"));

function Home() {
    const [sort, setSort] = useState("sales");
    const minRef = useRef();
    const maxRef = useRef();

    // Fetch all gigs without filtering by category
    const { isLoading, error, data, refetch } = useQuery({
        queryKey: ["allGigs"],
        queryFn: () =>
            newRequest
                .get(
                    `/services?min=${minRef.current?.value || 0}&max=${maxRef.current?.value || 1000}&sort=${sort}`
                )
                .then((res) => res.data),
    });

    const applyFilter = () => {
        refetch();
    };

    useEffect(() => {
        refetch();
    }, [sort]);

    const features = [
        {
            name: 'Network',
            description:
                'Networken Sie mit Freelancer und schreibe ihn einfach an, um über dein Projekt zu sprechen.',
            icon: globeAltIcon,
        },
        {
            name: 'Bekommen Sie Jobangebote als Freelancer',
            description:
                'Am Anfang kann es eine Herausforderung sein Aufträge zu bekommen. Wir sind für dich da, um dich bestmöglichst dabei zu unterstützen, damit du als Freelancer erfolgreich wirst.',
            icon: briefcaseIcon,
        },
        {
            name: 'Gute Freelancer für Ihre Projekte zu finden kann herausfordernd sein',
            description:
                'Durch unser Review System sehen Sie, ob es der passende Freelancer für Ihren Auftrag ist. Nach der Zusammenarbeit können Sie den Freelancer bewerten.',
            icon: faceSmileIcon,
        },
        {
            name: 'Nur Zahlen, wenn Sie wirklich zufrieden sind',
            description:
                'Es kann frustrierend sein, wenn man für etwas im voraus gezahlt hat und nicht das gewünschte Ergebnis bekommt. Deshalb zahlen Sie nur, wenn Sie zufrieden sind.',
            icon: fireIcon,
        },
    ];


    // Inhalte anzeigen, wenn der Benutzer nicht angemeldet ist
    return (
        <div className="container mx-auto py-4" id="services">
                    {isLoading && (
                        <div className="fixed inset-0 bg-white opacity-75 flex items-center justify-center">
                            <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-sky-500"></div>
                        </div>
                    )}

                    {error && (
                        <div className="fixed inset-0 bg-white opacity-75 flex items-center justify-center">
                            <span
                                className="text-red-500">Etwas ist schiefgelaufen, bitte versuchen Sie es erneut</span>
                        </div>
                    )}

            <div className="-mt-40 -mx-4 px-4 sm:px-8">
                <Link
                    to="/allservices"
                    className="text-blue-500 hover:underline"
                >
                    Alle Angebote
                </Link>
            </div>

            {/* Display gigs */}
            <div className="mx-auto flex flex-wrap -mx-4 px-4 sm:px-8 mt-10 bg-gray-200 rounded-xl">
                {Array.isArray(data) && data.length > 0 ? (
                    data.map((service) => (
                        <div
                                    key={service._id}
                                    className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 px-4 mb-4 mt-4"
                                >
                                    <GigCard item={service}/>
                                </div>
                            ))
                        ) : (
                            !isLoading && !error && (
                                <span className="text-center text-gray-900 text-lg w-full block mt-4">
                                Noch keine Uploads. Seien Sie der Erste.<br/>
                                <a href="/add">
                                    <button
                                        className="mt-4 bg-sky-500 text-white py-2 px-4 rounded-full hover:bg-sky-600">
                                        Hier Angebot veröffentlichen
                                    </button>
                                </a>
                            </span>
                            )
                        )}
                    </div>

            <Suspense fallback={<div>Loading...</div>}>
                <WhyPlanetbots/>
            </Suspense>

            <div className="bg-white">
                <div className="container mx-auto max-w-7xl py-12 sm:px-6 sm:py-32 lg:px-8">
                    <Suspense fallback={<div>Loading...</div>}>
                        <Featured/>
                    </Suspense>
                </div>
            </div>

            <div className="bg-white">
                <div className="container mx-auto max-w-7xl py-12 sm:px-6 sm:py-32 lg:px-8">
                    <Suspense fallback={<div>Loading...</div>}>
                        <LearnFromTheBestCTA/>
                    </Suspense>
                </div>
            </div>

            <div className="bg-white">
                <div className="container mx-auto max-w-7xl py-12 sm:px-6 sm:py-32 lg:px-8">
                    <Suspense fallback={<div>Loading...</div>}>
                        <NewsletterCTA/>
                    </Suspense>
                </div>
            </div>

            <div className="bg-white py-12 sm:py-32">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="mx-auto max-w-2xl lg:text-center">
                        <h2 className="text-2xl font-semibold leading-7 text-indigo-600">Unser Ziel</h2>
                        <p className="text-lg font-bold tracking-tight text-gray-900 sm:text-4xl">
                            ist es die Vermittlung zwischen Freelancer und Kunden so einfach wie möglich zu gestalten
                        </p>
                    </div>
                    <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
                        <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
                            {features.map((feature) => (
                                <div key={feature.name} className="relative pl-16">
                                    <dt className="text-base font-semibold leading-7 text-gray-900">
                                        <div
                                            className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                                            <feature.icon aria-hidden="true" className="h-6 w-6 text-white"/>
                                        </div>
                                        {feature.name}
                                    </dt>
                                    <dd className="mt-2 text-base leading-7 text-gray-600">{feature.description}</dd>
                                </div>
                            ))}
                        </dl>
                        <div className="mt-8 sm:mt-10 flex items-center justify-center">
                            <a
                                href="/register"
                                className="rounded-full bg-indigo-500 px-6 py-3 sm:px-8 sm:py-4 text-base sm:text-lg font-semibold text-gray-950 shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
                            >
                                Jetzt Registrieren
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <Suspense fallback={<div>Loading...</div>}>
                <Blog/>
            </Suspense>

            <CookieConsent
                location="bottom"
                buttonText="Accept"
                cookieName="myAwesomeCookie"
                style={{background: "#2B373B"}}
                buttonStyle={{color: "#4e503b", fontSize: "13px"}}
                expires={150}
            >
                {"Wir benutzen Cookies, um die Benutzererfahrung immer weiter zu verbessern."}
            </CookieConsent>
        </div>
    );
}

export default Home;
