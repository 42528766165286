import {createBrowserRouter, Outlet, RouterProvider} from "react-router-dom";
import React from "react";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import "./App.css"

import Navbar from "./components/navbar/Navbar.jsx";
import Footer from "./components/footer/Footer.jsx";
import Home from "./pages/home/Home.jsx";
import Gigs from "./pages/gigs/Gigs.jsx";
import Gig from "./pages/gig/Gig.jsx";
import Login from "./pages/login/Login.jsx";
import Register from "./pages/register/Register.jsx";
import Add from "./pages/add/Add.jsx";
import Orders from "./pages/orders/Orders.jsx";
import Messages from "./pages/messages/Messages.jsx";
import Message from "./pages/message/Message.jsx";
import MyGigs from "./pages/myGigs/MyGigs.jsx";
import Profile from "./pages/profile/Profile.jsx";
import VerificationPage from "./components/verificationPage/VerificationPage.jsx";
import ResetPasswordPage from "./pages/resetPassword/ResetPasswordPage.jsx";
import ForgotPassword from "./pages/forgotPassword/ForgotPassword.jsx";
import PrivacyPolicy from "./components/privacyPolicy/PrivacyPolicy.jsx";
import TermsOfService from "./components/termsOfService/TermsOfService.jsx";
import PropertyClaims from "./components/propertyClaims/PropertyClaims.jsx";
import Feedback from "./components/feedback/Feedback.jsx";
import AllServices from "./pages/allServices/AllServices.jsx";
import Cart from "./pages/cart/Cart.jsx";
import PriceSection from "./pages/price/PriceSection.jsx";
import SuccessPaypal from "./pages/sucessPaypal/SuccessPaypal.jsx";
import MySubscriptions from "./pages/mySubscriptions/MySubscriptions.jsx";
import Blog from "./components/blog/Blog.jsx";
import BlogPost from "./pages/blogpost/BlogPost.jsx";
import About from "./components/about/About.jsx";
import AllBlogs from "./pages/allBlogs/AllBlogs.jsx";



function App() {

        const jsonData = {
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "planetbots",
            "url": "https://www.planetbots.de",
            "logo": "https://www.planetbots.de/logo.png",
            "sameAs": [
                "https://www.instagram.com/planetbots.official",
                "https://www.tiktok.com/@planetbots"
            ],
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+491752436318",
                "contactType": "Customer Service"
            }
        }

    const queryClient = new QueryClient()

    const Layout = () => {


        return (

            <div>
                <QueryClientProvider client={queryClient}>
                    <Navbar/>
                    <Outlet/>
                    <Footer/>
                </QueryClientProvider>
                <script type="application/ld+json" dangerouslySetInnerHTML={{__html: JSON.stringify(jsonData)}}/>
            </div>

        );
    };

    const router = createBrowserRouter([
        {
            path: "/",
            element: <Layout/>,
            children: [
                {
                    path: "/",
                    element: <Home/>,
                },
                {
                    path: "/services",
                    element: <Gigs/>,
                },
                {
                    path: "/services/:category",
                    element: <Gigs/>,
                },
                {
                    path: "/myServices",
                    element: <MyGigs/>,
                },
                {
                    path: "/orders",
                    element: <Orders/>,
                },
                {
                    path: "/orders/:id",
                    element: <Orders/>,
                },
                {
                    path: "/messages",
                    element: <Messages/>,
                },
                {
                    path: "/message/:id",
                    element: <Message/>,
                },
                {
                    path: "/price",
                    element: <PriceSection />
                },
                {
                    path: "/add",
                    element: <Add/>,
                },
                {
                    path: "/service/:id",
                    element: <Gig/>,
                },
                {
                    path: "/profile/:username",
                    element: <Profile />
                },
                {
                    path: "/profile/:username",
                    element: <Profile />
                },
                {
                    path: "/cart",
                    element: <Cart />
                },
                {
                    path: "/success",
                    element: <SuccessPaypal />
                },
                {
                    path: "/mysubscription",
                    element: <MySubscriptions />
                },
                {
                    path: "/blog",
                    element: <Blog />
                },
                {
                    path: "/allblogs",
                    element: <AllBlogs />
                },
                {
                    path: "/post/:id",
                    element: <BlogPost />
                },
                {
                    path: "/about",
                    element: <About />
                },
                {
                    path: "/privacyPolicy",
                    element: <PrivacyPolicy />
                },
                {
                    path: "/termsofservice",
                    element: <TermsOfService />
                },
                {
                    path: "/propertyClaims",
                    element: <PropertyClaims />
                },
                {
                    path: "/allservices",
                    element: <AllServices />
                },
            ],
        },
        {
            path: "/register",
            element: <Register/>,
        },
        {
            path: "/verify-email",
            element: <VerificationPage/>,
        },
        {
            path: "/reset-password/:token",
            element: <ResetPasswordPage/>,
        },
        {
            path: "/forgot-password",
            element: <ForgotPassword/>
        },
        {
            path: "/login",
            element: <Login/>,
        },
        {
            path: "/feedback",
            element: <Feedback />
        },
        {
            path: "/feedback",
            element: <Feedback />
        },
    ]);

    return <RouterProvider router={router}/>;
}

export default App;