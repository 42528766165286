import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import newRequest from "../../utils/newRequest";
import moment from "moment";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";

const Messages = () => {
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    const queryClient = useQueryClient();

    // Mutation zum Markieren einer Nachricht als gelesen
    const mutationRead = useMutation({
        mutationFn: (id) => newRequest.put(`/conversations/${id}`),
        onSuccess: () => queryClient.invalidateQueries(["conversations"]),
    });

    // Mutation zum Löschen einer Konversation
    const mutationDelete = useMutation({
        mutationFn: (id) => newRequest.delete(`/conversations/deleteConversations/${id}`),
        onSuccess: () => queryClient.invalidateQueries(["conversations"]),
    });

    const handleRead = (id) => mutationRead.mutate(id);
    const handleDelete = (id) => mutationDelete.mutate(id);

    // Abrufen der Konversationen
    const { isLoading, error, data } = useQuery({
        queryKey: ["conversations"],
        queryFn: () => newRequest.get(`/conversations`).then((res) => res.data),
    });

    useEffect(() => {
        const messageList = document.getElementById("message-list");
        if (messageList) {
            messageList.scrollTop = messageList.scrollHeight;
        }
    }, [data]);

    return (
        <div className="container mx-auto py-4 px-4 sm:px-6 lg:px-8">
            <div className="bg-white shadow rounded-lg overflow-hidden">
                {isLoading ? (
                    <div className="flex justify-center items-center h-64">
                        <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-blue-500"></div>
                    </div>
                ) : error ? (
                    <div className="flex justify-center items-center h-64">
                        <div className="flex flex-col items-center">
                            <svg className="h-12 w-12 text-red-500 mb-2" viewBox="0 0 24 24">
                                <circle
                                    className="opacity-25"
                                    cx="12"
                                    cy="12"
                                    r="10"
                                    stroke="currentColor"
                                    strokeWidth="4"
                                ></circle>
                                <path
                                    className="opacity-75"
                                    fill="currentColor"
                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                ></path>
                            </svg>
                            <span className="text-red-500 text-lg">Bitte melden Sie sich an, um Ihre Nachrichten zu sehen</span>
                        </div>
                    </div>
                ) : data.length === 0 ? (
                    <div className="p-4 text-center">
                        <h2 className="text-xl font-semibold text-gray-800 mb-4">Noch keine Nachrichten</h2>
                        <p className="text-gray-600">Es sieht so aus, als hätten Sie noch keine Nachrichten erhalten.</p>
                    </div>
                ) : (
                    <div className="p-4">
                        <h1 className="text-2xl font-semibold text-gray-800 mb-4">Nachrichten</h1>
                        <table className="w-full bg-gray-50 border border-gray-200 rounded-lg">
                            <thead>
                            <tr className="bg-gray-100 border-b border-gray-200">
                                <th className="py-2 px-4 text-left text-gray-600 font-medium text-sm sm:text-base">
                                    {currentUser.isSeller ? "Käufer" : "Verkäufer"}
                                </th>
                                <th className="py-2 px-4 text-left text-gray-600 font-medium text-sm sm:text-base">
                                    Letzte Nachricht
                                </th>
                                <th className="py-2 px-4 text-left text-gray-600 font-medium text-sm sm:text-base">
                                    Datum
                                </th>
                                <th className="py-2 px-4 text-left text-gray-600 font-medium text-sm sm:text-base">
                                    Aktion
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {data.map((c) => (
                                <tr
                                    className={`border-b border-gray-200 ${
                                        ((currentUser.isSeller && !c.readBySeller) ||
                                            (!currentUser.isSeller && !c.readByBuyer))
                                            ? "bg-gray-200"
                                            : ""
                                    }`}
                                    key={c._id}
                                >
                                    <td className="py-2 px-4 text-sm sm:text-base">
                                        <Link
                                            to={`/profile/${currentUser.isSeller ? c.buyerId.username : c.sellerId.username}`}
                                            className="text-blue-600 hover:underline"
                                        >
                                            {currentUser.isSeller ? c.buyerId.username : c.sellerId.username}
                                        </Link>
                                    </td>
                                    <td className="py-2 px-4 text-sm sm:text-base">
                                        <Link
                                            to={`/message/${c.id}`}
                                            className="text-blue-600 hover:underline"
                                        >
                                            {c?.lastMessage?.substring(0, 100)}...
                                        </Link>
                                    </td>
                                    <td className="py-2 px-4 text-sm sm:text-base text-gray-500">
                                        {moment(c.updatedAt).fromNow()}
                                    </td>
                                    <td className="py-2 px-4 text-sm sm:text-base">
                                        {((currentUser.isSeller && !c.readBySeller) ||
                                            (!currentUser.isSeller && !c.readByBuyer)) ? (
                                            <button
                                                onClick={() => handleRead(c._id)}
                                                className="text-blue-600 hover:underline"
                                            >
                                                Als gelesen markieren
                                            </button>
                                        ) : (
                                            <DeleteForeverOutlinedIcon
                                                onClick={() => handleDelete(c._id)}
                                                className="text-red-600 cursor-pointer hover:text-red-800"
                                            />
                                        )}
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Messages;
